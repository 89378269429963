.extras-page {
  align-items: center;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction:row;
  max-width: 100vw;
}
.extras-page.desktop.with-playlist {
  display: grid;
  grid-template-columns: 1fr var(--playlist-width);
  position: relative;
}

.extras-page .left-wrapper {
  align-items: center;
  display: flex;
  flex: auto;
  flex-direction: column;
  padding: unset;
  position: relative;
  max-width: calc(100vs - var(--playlist-width));
  overflow-x: hidden;
}

.extras-page .right-wrapper {
  display: flex;
  flex-direction: column;
  flex: auto;
  position: fixed;
  top: 0;
  right: 2px;
  height: calc(100vh - 80px);
  box-shadow: var(--shadow-drop-shadow);
  width: var(--playlist-width);
}
.extras-page:not(.with-playlist) .right-wrapper {
  display: none;
}
.extras-page.tablet.with-playlist .left-wrapper,
.extras-page.mobile.with-playlist .left-wrapper {
  display: none;
}
.extras-page.tablet.with-playlist .right-wrapper,
.extras-page.mobile.with-playlist .right-wrapper {
  width: 100vw;
}

.extras-page .main-content-area {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  width: 98%;
}

.extras-page .left-frame {
  align-items: flex-start;
  display: flex;
  flex: auto;
  flex-direction: column;
  gap: 8px;
  padding: 6px;
  position: relative;  
  overflow: hidden;
}

.extras-page .movements-container {
  width: 100%;
  justify-content: center;
}

.extras-page .right-frame {
  align-items: flex-end;
  display: inline-flex;
  flex: auto;
  flex-direction: column;
  width: 100%;
  position: relative;
}

