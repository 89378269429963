.obt-logo {
  position: relative;  
  left: 1px;
  top: 1px;
  border-radius: 6px;
}

.obt-logo.style-desktop.akuo {
  height: 4rem;
}

.obt-logo.style-tablet.akuo {
  height: 3rem;
}

.obt-logo.style-mobile.akuo {
  height: 2rem;
}

.obt-logo.style-desktop.obthelps {
  /* max-width: 120px; */
  height: 4rem;
}

.obt-logo.style-tablet.obthelps {
  /* max-width: 120px; */
  height: 3rem;
}

.obt-logo.style-mobile.obthelps {
  /* max-width: 48px; */
  height: 2rem;
}

.obt-logo-nav:hover {
  cursor: pointer;
}
