.obt-button {
  border-radius: .5rem;
  align-items: center;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  position: relative;
  white-space: pre-wrap;  /* to recognize newline in text */
}

.obt-button .text-button {
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.obt-button .obt-placeholder-instance {
  height: 22px !important;
  width: 22px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
}

.obt-button.size-0-sm {
  /* height: 1.5rem; */
  padding: 4px 4px;
}

.obt-button.size-0-md {
  /* height: 1.75rem; */
  padding: 10px 16px;
}

.obt-button.size-0-lg {
  /* height: 2rem;   */
  padding: 11px 18px;
}

.obt-button.size-0-xl {
  /* height: 2.25rem; */
  padding: 12px 20px;
}

/* Less padding for links without borders */
.obt-button.link-color,
.obt-button.link-gray,
.obt-button.tertiary-gray {
  padding: 0px;
}

.obt-button.link-gray.obt-button-bible-home {
  text-decoration: underline;
}

.obt-button.primary,
.obt-button.secondary-gray {
  border: 1px solid;
  box-shadow: var(--shadow-button-drop-shadow);
}

.obt-button.state-3-focused.secondary-color {
  background-color: var(--primary-50);
  border-color: var(--primary-200);
  box-shadow: var(--shadow-button-drop-shadow);
}

.obt-button.primary.state-3-hover {
  background-color: var(--apm-project-blue700);
  border-color: var(--apm-project-blue700);
  box-shadow: var(--shadow-button-hover);
}

.obt-button.state-3-focused.primary {
  background-color: var(--primary-600);
  border-color: var(--primary-600);
  box-shadow: var(--shadow-button-drop-shadow);
}

.obt-button.primary.state-3-disabled {
  background-color: var(--primary-200);
  border-color: var(--primary-200);
  box-shadow: var(--shadow-button-hover);
}

.obt-button.tertiary-gray.state-3-hover {
  /* Tertiary gray is a link with no border */
  background-color: var(--gray-50);
}

.obt-button.secondary-color.state-3-disabled {
  background-color: var(--primary-25);
  border-color: var(--primary-25);
  box-shadow: var(--shadow-button-hover);
}

.obt-button.state-3-default.secondary-color {
  background-color: var(--primary-50);
  border-color: var(--primary-200);
  box-shadow: var(--shadow-button-hover);
}

.obt-button.secondary-gray.state-3-default {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.obt-button.secondary-gray.state-3-disabled {
  background-color: var(--basewhite);
  border-color: var(--gray-200);
}

.obt-button.secondary-gray.state-3-hover {
  background-color: var(--gray-50);
  border-color: var(--gray-300);
}

.obt-button.secondary-gray.state-3-focused {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.obt-button.secondary-color.state-3-hover {
  background-color: var(--primary-100);
  border-color: var(--primary-200);
  box-shadow: var(--shadow-button-hover);
}

.obt-button.primary.state-3-default {
  background-color: var(--primary-600);
  border-color: var(--primary-600);
  box-shadow: var(--shadow-button-drop-shadow);
}

.obt-button.secondary-color .text-button {
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
}

.obt-button.link-color .text-button {
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
}

.obt-button.secondary-gray .text-button {
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
}

.obt-button.link-gray .text-button {
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
}

.obt-button.tertiary-gray .text-button {
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
}

.obt-button.primary .text-button {
  color: var(--basewhite);
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
}

.obt-button.state-3-focused.secondary-color .text-button {
  color: var(--apm-project-blue700);
}

.obt-button.tertiary-gray.state-3-disabled .text-button {
  color: var(--gray-300);
}

.obt-button.state-3-focused.link-color .text-button {
  color: var(--primary-800);
}

.obt-button.link-color.state-3-disabled .text-button {
  color: var(--gray-300);
}

.obt-button.state-3-focused.tertiary-gray .text-button {
  color: var(--gray-600);
}

.obt-button.link-gray.state-3-default .text-button {
  color: var(--gray-600);
}

.obt-button.tertiary-gray.state-3-hover .text-button {
  color: var(--gray-700);
}

.obt-button.link-gray.state-3-disabled .text-button {
  color: var(--gray-300);
}

.obt-button.state-3-hover.link-gray .text-button {
  color: var(--gray-700);
}

.obt-button.secondary-color.state-3-disabled .text-button {
  color: var(--primary-300);
}

.obt-button.state-3-default.link-color .text-button {
  color: var(--primary-700);
}

.obt-button.state-3-default.secondary-color .text-button {
  color: var(--apm-project-blue700);
}

.obt-button.secondary-gray.state-3-default .text-button {
  color: var(--gray-700);
}

.obt-button.secondary-gray.state-3-disabled .text-button {
  color: var(--gray-300);
}

.obt-button.secondary-gray.state-3-hover .text-button {
  color: var(--primary-800);
}

.obt-button.state-3-focused.secondary-gray .text-button {
  color: var(--gray-700);
}

.obt-button.state-3-hover.link-color .text-button {
  color: var(--primary-700);
}

.obt-button.state-3-hover.secondary-color .text-button {
  color: var(--primary-800);
}

.obt-button.state-3-focused.link-gray .text-button {
  color: var(--gray-600);
}

.obt-button.tertiary-gray.state-3-default .text-button {
  color: var(--gray-600);
}

.obt-button:hover {
  cursor: pointer;
}

.obt-button .text-align-left {
  text-align: left;
}