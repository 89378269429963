.obt-toggle {
  align-items: flex-start;
  position: relative;
}

.obt-toggle .button-wrapper {
  align-items: center;
  border-radius: 12px;
  display: flex;
  overflow: hidden;
  padding: 2px;
  position: relative;
}

.obt-toggle .div {
  box-shadow: var(--shadow-sm);
  position: relative;
}

.obt-toggle {
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
}

.obt-toggle .size-md {
  height: 24px;
  width: 44px;
}

.obt-toggle .size-sm {
  height: 20px;
  width: 36px;
}

.obt-toggle .state-disabled {
  background-color: var(--gray-100);
}

.obt-toggle .state-focus {
  box-shadow: var(--focus-ring-4px-primary-100);
}

.obt-toggle .pressed-true {
  justify-content: flex-end;
}

/* .obt-toggle .pressed-true.state-focus {
  background-color: var(--primary-600);
} */

/* .obt-toggle .state-default.pressed-true {
  background-color: var(--primary-600);
} */

.obt-toggle .state-hover.pressed-false {
  background-color: var(--gray-300);
}

.obt-toggle .pressed-false.state-focus {
  background-color: var(--gray-100);
}

/* .obt-toggle .state-hover.pressed-true {
  background-color: var(--primary-700);
} */

.obt-toggle .state-default.pressed-false {
  background-color: var(--gray-200);
}

.obt-toggle .button-wrapper.size-md .div {
  border-radius: 10px;
  height: 20px;
  width: 20px;
}

.obt-toggle .button-wrapper.size-sm .div {
  border-radius: 8px;
  height: 16px;
  width: 16px;
}

.obt-toggle .button-wrapper.state-disabled .div {
  background-color: var(--gray-50);
}

.obt-toggle .button-wrapper.state-hover .div {
  background-color: var(--basewhite);
}

.obt-toggle .button-wrapper.state-focus .div {
  background-color: var(--basewhite);
}

.obt-toggle .button-wrapper.state-default .div {
  background-color: var(--basewhite);
}


 /* toggle-base */

 .obt-toggle-base {
  align-items: center;
  border-radius: 12px;
  display: flex;
  overflow: hidden;
  padding: 2px;
  position: relative;
}

.obt-toggle-base .button {
  box-shadow: var(--shadow-sm);
  position: relative;
}

.obt-toggle-base.md {
  height: 1rem;
  width: 2rem;
}

.obt-toggle-base.sm {
  height: .5rem;
  width: 1rem;
}

.obt-toggle-base.disabled {
  background-color: var(--gray-100);
}

.obt-toggle-base.focus {
  box-shadow: var(--focus-ring-4px-primary-100);
}

.obt-toggle-base.pressed-true {
  justify-content: flex-end;
}


.obt-toggle-base.default.pressed-false {
  background-color: var(--gray-100);
}


.obt-toggle-base.hover.pressed-false {
  background-color: var(--gray-200);
}

.obt-toggle-base.pressed-false.focus {
  background-color: var(--gray-50);
}


.obt-toggle-base.md .button {
  border-radius: 0.5rem;
  height: 1rem;
  width: 1rem;
}

.obt-toggle-base.sm .button {
  border-radius: 8px;
  height: 16px;
  width: 16px;
}

.obt-toggle-base.disabled .button {
  background-color: var(--gray-50);
}

.obt-toggle-base.hover .button {
  background-color: var(--basewhite);
}

.obt-toggle-base.focus .button {
  background-color: var(--basewhite);
}

.obt-toggle-base.default .button {
  background-color: var(--basewhite);
}
