.mobile-menu {
  height: 30px;
  width: 30px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.mobile-menu-burger {
    width: 100%;
    height: 100%;
}

