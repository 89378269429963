.downloader {
    width: 400px;
    min-height: 130px;
    max-height: 700px;
    position: fixed;
    right: 18px;
    bottom: 80px;
    overflow-y: auto;
    font-family: var(--text-sm-medium-font-family);
    font-size: var(--text-sm-medium-font-size);
    font-style: var(--text-sm-medium-font-style);
    font-weight: var(--text-sm-medium-font-weight);
    letter-spacing: var(--text-sm-medium-letter-spacing);
    line-height: var(--text-sm-medium-line-height);
    z-index:4;
  }

  .downloader .card {    
    overflow-y: auto;
    background-color: var(--primary-50);
  }
  .downloader .card-header {
    color: white;
    background-color: var(--primary-600);
  }
  .downloader .card .file-list-group {
    max-height: 300px;
    min-height: 128px;
    margin: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;    
  }

  .downloader-progress-bar {
    width: 80%;
  }

  .downloader-countdown {
    display: inline-block;
    width: 200px;
  }