
.breadcrumbs {
    /* max-width: 50%; */
    margin: 0.5em;
    position: relative;  
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .breadcrumbs * {
    display: inline-block;
    margin-right: 0 0.5em;
    vertical-align: middle;
  }
  
  .breadcrumbs svg.breadcrumb-right {
    margin: 0.25em 0.5em 0.25em;
  }
  
  /* final breadcrumb is the current page, so no link needed */
  /* 3/20/2024 - Moved the last breadcrumb to header */
  /* .breadcrumbs .crumb:last-child {
    pointer-events: none;
  } */

  .breadcrumbs .crumb:last-child svg.breadcrumb-right {
    display: none;  
  }
  
  .breadcrumbs .crumb.crumb-label {
    font-family: var(--text-sm-medium-font-family);
    font-size: var(--text-sm-medium-font-size);
    font-style: var(--text-sm-medium-font-style);
    font-weight: var(--text-sm-medium-font-weight);
    letter-spacing: var(--text-sm-medium-letter-spacing);
    line-height: var(--text-sm-medium-line-height);
    vertical-align: middle;
  }

  .breadcrumbs .crumb.crumb-label a {
    text-decoration: underline;
  }
  
  .breadcrumbs .crumb.home a {    
    content: url(../../../public/img/obt-home-line.svg);
  }