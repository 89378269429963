.obt-nav-menu-item {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 16px;
  /* min-height: 72px; */
  /* min-width: 312px; */
  padding: 2px;
  position: relative;
  /* max-width: 100%; */
  flex-wrap: nowrap;
}

.obt-nav-menu-item.support-menu-item {
  max-width: 150%;
  flex-wrap: nowrap;
  overflow-x:visible;
}

.obt-nav-menu-item .content {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  position: relative;
  flex-wrap: wrap;
}

.obt-nav-menu-item .icon {
  height: 20px !important;
  /* position: relative !important; */
  width: 20px !important;
}

.obt-nav-menu-item .text-and-supporting {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  gap: 4px;
  position: relative;
  max-width: 100%;
  text-wrap: wrap;
}

.obt-nav-menu-item .text-and-badge {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.obt-nav-menu-item .text {
  color: var(--gray-900);
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.obt-nav-menu-item .supporting-text {
  align-self: stretch;
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  position: relative;
}

.obt-nav-menu-item img:hover {
  cursor: pointer;
}


.obt-nav-menu-item .text:hover {
  cursor: pointer;
  text-decoration: underline;
}
