/* Complete copyright section styling */
.copyright-content {
  text-align: left;
  align-items: stretch;
  position: relative;
  padding: 1rem 1rem 0;
}

.copyright-content .copyright-text {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 4px;
  position: relative;
  width: 100%;
}

/* Overlay styling */
.copyright-overlay {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadow-drop-shadow);
  display: flex;
  flex-direction: column;  
  position: absolute;
  bottom: 20%; /*Position just above the info-icon*/
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  align-items: flex-start;
}


.copyright-overlay .content {
  align-items: flex-start;
  /* text-align: justify; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  position: relative;
  width: 100%;
}

.copyright-overlay .akuo-icon {
  height: 30px;
  object-fit: cover;
  position: relative;
  width: 29.64px;
}

.copyright-content .title {
  color: var(--gray-500);
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.copyright-overlay .description {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  margin-right: -2px;
  position: relative;
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
}

.copyright-overlay .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 290px;
  text-wrap: wrap;
}

/* .copyright-overlay .copyright {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;  
  position: relative;
  width: 300px;
} */

.copyright-overlay .copyright-text {
  margin-top: -1px;
  position: relative;  
  text-wrap: wrap;
}

.copyright-overlay .copyright-text a span {
  color: var(--apm-project-blue500);
}

.copyright-overlay .span,
.copyright-overlay .copyright-text {
  color: var(--gray-700);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
}

.copyright-overlay .copyright-image {
  height: 23.25px;
  object-fit: cover;
  position: relative;
  width: 66px;
}
/* Show and Hide the Bible Info overlay */
.copyright-overlay {  
  visibility: hidden;
  opacity: 0;
  transition: all var(--speed) ease-out;
}

.info-bible-icon:hover+.copyright-overlay,
.copyright-text:hover+.copyright-overlay {
  visibility: visible;
  overflow: visible;
  opacity: 1;
  transition: all var(--speed) ease-in;
}

.copyright-content:hover  {
  visibility: visible;
  overflow: visible;
  opacity: 1;
  transition: all var(--speed) ease-in; 
}

.info-bible-icon:hover {
  cursor:help;
}

.info-bible-container {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
}