.category-modal-wrapper {
  display: flex;
  flex: 0 0;
  max-width: 3rem;
  min-width: 3rem;
  justify-content: center;
}

.tablet .category-modal-wrapper,
.mobile .category-modal-wrapper {
  display: inline-flex;
  justify-content: center;
  width: auto;
}

button.category-filter {
  background: none;
  border: none;
  cursor: pointer;
}

.category-filter {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
}

.tablet .category-filter,
.mobile .category-filter {
  display: inline-block;
}

.audio-controls-container .category-filter  svg {
  width: 30px;
  height: 30px;
  padding: 0 4px 2px 0;
  color: var(--gray-500);
}

/* Tooltip text */
.category-filter .tooltip {
  visibility: hidden;
  text-align: center;
  position: relative;
  overflow: visible;
  position: absolute;
  top: -1.5rem;
  right: -1rem;
  z-index: 2;
}

.tablet .category-filter .tooltip,
.mobile .category-filter .tooltip {
  right: -1rem;
}

.category-filter:hover .tooltip  {
  visibility: visible;
}
