.chapters {
  align-items: flex-start;
  border-radius: 6px;
  display: flex;
  position: relative;
}

.chapters .frame-chapters {
  align-items: flex-start;
  /* background: linear-gradient(180deg, rgba(144, 168, 40, 0.18) 0%, rgba(144, 168, 40, 0) 100%); */
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  min-height: var(--breakpoints-minelementsheight);
  padding: 18px 48px;
  position: relative;  
  align-content: flex-start;
  justify-content: center;
  width: 95vw;
}

.chapters.story.small {
  gap: 10px;
}

.chapters.small .frame-chapters {
  gap: 48px 48px;
}

.chapters.traditional .frame-chapters {
  overflow: hidden;
}

.chapters.large .frame-chapters {
  gap: 48px 48px;
  overflow: hidden;

}

.chapters.story.small .frame-chapters {
  align-self: stretch;
}
