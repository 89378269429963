.playback-loop-wrapper {
  display: flex;
  flex: 0 0;
  max-width: 3rem;
  min-width: 3rem;
  justify-content: center;
}

.tablet .playback-loop-wrapper,
.mobile .playback-loop-wrapper {
  display: inline-flex;
  justify-content: center;
  width: auto;
}

button.playback-loop  {
  background: none;
  border: none;
  cursor: pointer;
}

.playback-loop {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
}

.tablet .playback-loop,
.mobile .playback-loop {
  display: inline-block;
  /* position: relative;
  align-content: center; */
}

.audio-controls-container .playback-loop  svg {
  width: 30px;
  height: 30px;
  padding: 0 4px 2px 0;  
  color: var(--gray-500);
}

/* Tooltip text */
.playback-loop .tooltip {
  visibility: hidden;
  text-align: center;
  overflow: visible;
  position: absolute;
  top: -1.5rem;
  right: 0;
  z-index: 2;
}

.tablet .playback-loop .tooltip,
.mobile .playback-loop .tooltip {
  right: -4px;
}

.playback-loop:hover .tooltip  {
  visibility: visible;  
}