.resource-sections {
  display:grid;
  grid-template-columns: 1fr;
  width: 95%;
  position:relative;
  overflow: visible;
  /* box-shadow: 0px 1px 1px #00000040;   */
}

.resource-sections .obtresourcesection {
  width: 100%;
}

.resource-sections .frame-resource-sections {  
  display: flex;
  flex: 0;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  position: relative;  
  align-content: flex-start;
  justify-content: center;
  /* overflow: hidden; */
  border-radius: 6px;  
  padding: 1rem 0 1rem var(--resource-level1-width-small);  /* extra padding on the left to indent past the parent image   */
  width: 100%;
}

.resource-sections.small {
  gap: 10px;
}

.resource-sections.small .frame-resource-sections {
  gap: 8px 8px;
}


.frame-resource-sections .skeleton-box  {
  width: 50% !important;
  overflow: hidden;
}

.resource-sections .passage-heading,
.resource-sections .audio-note-heading {  
  width: 100%;
  background-color: var(--gray-100);
  color: var(--gray-800);
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
  position: relative;
  text-align: flex-start;
}

.resource-sections .audio-note-heading {
  margin-top: 1rem;
}

.resource-sections .audio-note-heading,
.resource-sections .resource-link-instance {
  margin-left: var(--resource-level1-width-small);
  width: 90%;
}


.resource-sections .section-filler-span {
  display: none;
}