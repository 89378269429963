.sections {
  align-items: flex-start;
  /* border-radius: 6px; */
  /* box-shadow: 0px 4px 4px #00000040; */
  display: flex;
  position: relative;
  width: 100%;  
}

.sections .frame-sections {
  align-items: flex-start;
  /* background: linear-gradient(180deg, rgba(144, 168, 40, 0.18) 0%, rgba(144, 168, 40, 0) 100%); */
  /* border-radius: 6px; */
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  min-height: var(--breakpoints-minelementsheight);
  padding: var(--frame-main-padding);
  position: relative;  
  align-content: flex-start;
  justify-content: center;  
}

.sections.story.small {
  gap: 10px;
}

.sections.small .frame-sections {
  gap: 10vh 48px;
}

.sections.traditional .frame-sections {
  overflow: hidden;
}

.sections.large .frame-sections {
  gap: 48px 48px;
  overflow: hidden;
}

.sections.story.small .frame-sections {
  align-self: stretch;
}

.audio-notes {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px 5px;
  justify-content: center;
  min-height: 73px;
  width: var(--card-width-large);
  position: relative;  
}

.sections.small .audio-notes {
  width: var(--card-width-small);
}

.audio-notes .frame-audio-notes {
  align-items: flex-start;
  border: 1px dashed;
  border-color: var(--gray-400);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;  
  padding: 5px 0px;
  position: relative;
  width: 105%;
}

.audio-notes .frame-note-heading {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 4px;
  position: relative;
  width: 100%;
}

.audio-notes .text-wrapper {
  color: var(--baseblack);
  font-family: var(--text-xxs-medium-font-family);
  font-size: var(--text-xxs-medium-font-size);
  font-weight: var(--text-xxs-medium-font-weight);    
  letter-spacing: var(--text-xxs-medium-letter-spacing);
  line-height: var(--text-xxs-medium-line-height);
  margin-top: -1px;
  position: relative;
  text-shadow: 0px 4px 4px #00000040;
  /* text-shadow: var(--shadow-xs); */
  white-space: nowrap;
  height: 11px;
  width: 88px;
}

.audio-notes .vector {
  height: 8px;
  position: relative;
  width: 8px;
}

.audio-notes .frame-note-cards {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 4px 4px;
  /* max-width: 95%; */
  padding: 4px;
  position: relative;
}

.audio-notes .audio-note-card-instance {
  flex: 0 0 auto !important;
  left: unset !important;
  top: unset !important;
}

.audio-notes .expand-audio-notes {
  margin: 0 0.5rem;
}

.audio-notes .expand-audio-notes:hover {
  cursor: pointer;
}

