.obt-expand-all {
  position: relative;
  display: inline-flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  align-self: center;
  transition: .4ms ease-in-out;
}

.obt-expand-all-icon:hover {
  cursor: pointer; 
  transform: scale(1.2);
}

.obt-expand-all .obt-expand-all-icon {  
  height: 1.5rem;
  width: 1.5rem;  
}

.obt-expand-all .expand-frame {
  display: inline-grid;  
  grid-column: 2;
  grid-row: 1;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;  
}

.obt-expand-all .expand-icon-frame {
  align-items: center;
  justify-items: center;
  max-width: 30px;
  overflow: visible;
  padding: 0 2px;
  position: relative;
}

.obt-expand-all .expand-tooltip {  
  /* position: relative; */
  position: absolute;
  white-space: nowrap;
  text-align: center;
  align-self: center;
  overflow: visible;   
  z-index: 2;
  bottom: 100%;
  left: -75%;  
  visibility: hidden;
}

.obt-expand-all .expand-icon-frame:hover .expand-tooltip  {
  visibility: visible;
}

