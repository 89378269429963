.audio-note-card {
    align-items: center;
    display: inline-flex;
    gap: 2rem;
    justify-content: left;
    min-height: 20px;    
    position: relative;
    margin: 0 0.5rem;      
  }

  .audio-note-card:hover {
     cursor: pointer;
     box-shadow: 0px 4px 4px #00000040;
     background: var(--primary-50);
  }

  .audio-note-card.card-mode-small {
    max-width: 100%;
    min-width: 100%;
  }
  
  .audio-note-card .frame-card {
    align-items: flex-start;    
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: hidden;
    position: relative;    
    width: 100%;
  }

  .audio-note-card.card-mode-mini .frame-card {
    box-shadow: var(--shadow-audio-card-drop-shadow);
  }

  /* 
  .audio-note-card.card-mode-small .frame-card {
    width: 100%;
  } */
  
  /* .audio-note-card .frame-background {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 5.62px;
    position: relative;
    width: 100%;
  } */
  
  .audio-note-card .frame-overlay {
    align-items: center;
    align-self: stretch;    
    display: flex;
    /* flex: 0 0 auto; */
    justify-content: flex-start;
    position: relative;
    width: 100%;
    gap: 0.5rem;
  }

  .audio-note-card .frame-title {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .audio-note-card .frame-image {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    position: relative;
    box-shadow: var(--shadow-audio-card-drop-shadow);
  }

  .audio-note-card .card-name {
    font-family: var(--text-xs-regular-font-family);
    font-size: var(--text-xs-regular-font-size);
    font-style: var(--text-xs-regular-font-style);
    font-weight: var(--text-xs-regular-font-weight);  
    letter-spacing: var(--text-xs-regular-letter-spacing);
    line-height: var(--text-xs-regular-line-height);  
  }
  
  .audio-note-card .audio-note-image {
    height: 20px;
    object-fit: cover;
    position: relative;
    width: 20px;
  }
  
  .audio-note-card button.icon-img {
    padding: 0;
    background: transparent;
    border: 0;
  }
  .audio-note-card button .title-icon-img {    
    color: black;
    align-self: stretch;
    flex: 0 0 auto;
    position: relative;
    background-size: contain;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    width: 30px;
    z-index: 2;
  }