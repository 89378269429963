.obt-download-list {
  background-color:var(--apm-project-blue50-50pct );
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  overflow: hidden;
  top: 1%;
  right: 1%;
  transition: .4ms ease-in-out;
}

.obt-download-list.card-download-small,
.obt-download-list.resource-download-small {
  height: 1rem;
  width: 1rem;
}

.obt-download-list.card-download-small:hover,
.obt-download-list.card-download-large:hover {
  overflow: visible;
  cursor: pointer;
  transform: scale(1.2);
}

/* .obt-download-list-icon:hover {
  cursor: pointer; 
  transform: scale(1.2);
} */

.obt-download-list-icon {  
  height: 20px;
  width: 20px;
}

.obt-download-list-frame {
  align-items: center;
  justify-items: center;  
}

.obt-download-list .icon-frame {
  align-items: center;
  justify-items: center;
  max-width: 28px;
  overflow: visible;
  padding: 0 2px;
  position: relative;
}

.obt-download-list .tooltip {  
  /* position: relative; */
  position: absolute;
  white-space: nowrap;
  text-align: center;
  align-self: center;
  overflow: visible;   
  z-index: 2;
  bottom: 100%;
  left: -100%;  
  visibility: hidden;
}

.obt-download-list .icon-frame:hover .tooltip  {
  visibility: visible;
}

