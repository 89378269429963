*, *::before, *::after { box-sizing: inherit;}

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter',system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration:none;
}

/* Shimmer effect for placeholder elements during data loading */
.shimmer {
  color:var(--gray-200);
  background: var(--gray-200);
  background-image: linear-gradient(to right, var(--gray-200) 0%, var(--gray-100) 20%, var(--gray-200) 40%, var(--gray-200) 100%);
  background-repeat: no-repeat;      
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
  animation-duration: 1.5s;
  animation-fill-mode: forwards; 
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}
@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}


.slider-loader {
  width: 120px;
  height: 20px;
  background: 
    /* linear-gradient(90deg,#0001 33%,#0005 50%,#0001 66%) */
    linear-gradient(to right, var(--gray-200) 0%, var(--gray-100) 20%, var(--gray-200) 40%, var(--gray-200) 100%)
    #f2f2f2;
  background-size:300% 100%;
  animation: l1 1s infinite linear;
}
@keyframes l1 {
  0% {background-position: right}
}