html {
  font-family: var(--primary-font-family);
}

.tooltip {
  color: var(--apm-project-blue700);
  font-family: var(--tooltip-font-family);
  font-size: var(--tooltip-font-size);
  font-style: var(--tooltip-font-style);
  font-weight: var(--tooltip-font-weight);
  letter-spacing: var(--tooltip-letter-spacing);
  line-height: var(--tooltip-font-size);
  background-color: var(--gray-500);
  color: var(--gray-50);
  border-radius: 6px;
  padding: 4px 6px;
  box-shadow: var(--shadow-button-hover);
}

.background-wallpaper {
  background: url(../../../public/img/background-white.png) repeat top left;
}

.home-page {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  /* flex-direction: column; */
  position: relative;
  /* justify-content: center; */
  flex-direction:row;
  max-width: 100vw;
  overflow-y: auto;
}
.home-page.desktop.with-playlist {
  display: grid;
  grid-template-columns: 1fr var(--playlist-width);
  position: relative;
}

.home-page .home-left-wrapper {
  align-items: center;
  display: flex;
  flex: auto;
  flex-direction: column;
  padding: unset;
  position: relative;
  max-width: calc(100vs - var(--playlist-width));
  overflow-x: hidden;
  overflow-y: hidden;
}

.home-page .home-right-wrapper {
  display: flex;
  flex-direction: column;
  flex: auto;
  position: fixed;
  top: 0;
  right: 2px;
  height: calc(100vh - 80px);
  box-shadow: var(--shadow-drop-shadow);
  width: var(--playlist-width);
}
.home-page:not(.with-playlist) .home-right-wrapper {
  display: none;
}
.home-page.tablet.with-playlist .home-left-wrapper,
.home-page.mobile.with-playlist .home-left-wrapper {
  display: none;
}
.home-page.tablet.with-playlist .home-right-wrapper,
.home-page.mobile.with-playlist .home-right-wrapper {
  width: 100vw;
}

.home-page .home-main-content-area {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  /* width: 98%; */
}

.hero-header-section {  
  position: relative;
  display: flex;
  flex-direction: column;
  left: 0;  
  top: 0;
  width: 95%;
  align-items: center;
}

.obt-hero-header-instance {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  left: unset !important;
  top: unset !important;
  width: 100% !important;
  z-index: 0 !important;
}

.obt-hero-header-2 {
  align-self: stretch !important;
  width: 100% !important;
}

.obt-hero-header-3 {
  text-align: center;
  left: unset !important;
}

.obt-hero-header-5 {
  left: 0 !important;
  z-index: 2;
}

.frame-tabs-container {
  position: relative;
  display: flex;
  flex: 1;
  padding-top: 1rem;
  justify-content: center;
  align-items: center;
  align-self: stretch; 
  z-index: 4;
}

.frame-tabs  {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-items: center;
  width: 95%;
}

.frame-tabs-left  {
  justify-self: start;
  white-space: nowrap;
}

.frame-tabs-center {
  position: absolute; 
  left: 50%; 
  transform: translateX(-50%); 
}

.frame-tabs-tablet {
  transform: scale(90%,90%);
  padding: 8px 0px !important;
}  

.frame-tabs-mobile {
  transform: scale(90%,90%);
}

.horizontal-tabs {    
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  justify-self: end;
  position: relative;
}

.horizontal-tabs {
  justify-self: end; /* Aligns icons to the right */
}

.mobile .horizontal-tabs {
  gap: 0;
}

.story-mode-toggle {
  left: unset !important;
  top: unset !important;
}

.button {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  overflow: hidden;
  padding: 0px 6px;
  position: relative;
}

.obt-view-buttons-instance {
  left: unset !important;
  position: relative !important;
  top: unset !important;
}

/* .books {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
} */

.contact-sections {
  background-color: #ffffff;
  position: relative;
  display: grid;
  grid-template-columns: repeat(1fr);
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 32px;
  padding: 16px 0px;
  width: 100%;
}

.content-wrapper {
  align-items: flex-start;
  align-self: stretch;  
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  padding: 0px 32px;
  position: relative;
  width: 95%;
}

.content-contact {
  display: flex;  
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 24px;
  position: relative;
  width: 100%;
  align-items: center;
  padding-top: 2rem;
}

.contact-box {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f8f9fb;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 18px;
  padding: 24px;
  position: relative;
}

.contact-icon-wrapper {
  display: grid;
  grid-template-columns: 1fr 4fr;
  justify-items: space-between;
  width: 100%;
  gap: 12px;
}

.featured-icon {
  background-color: var(--primary-600);
  border-radius: 10px;
  height: 3rem;
  width: 3rem;
  position: relative;  
  display: flex;
  align-items: center;
  justify-content: center;
}

.obt-icon {
  height: 1.75rem;
  width: 1.75rem;
}

.contact-details {
  align-items: flex-start;
  justify-self: center;
  /* align-self: flex-start; */
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
  padding-left: 0.5rem;
}

.contact-details .obt-button-link {
  left: -2px;
}

.contact-text {
  align-self: center;
  color: var(--primary-800);
  font-family: var(--text-xl-semibold-font-family);
  font-size: var(--text-xl-semibold-font-size);
  font-style: var(--text-xl-semibold-font-style);
  font-weight: var(--text-xl-semibold-font-weight);
  letter-spacing: var(--text-xl-semibold-letter-spacing);
  line-height: var(--text-xl-semibold-line-height);
  margin-top: -1px;
  position: relative;
}

.supporting-text {
  align-self: stretch;
  color: var(--gray-600);
  font-family: var(--text-md-regular-font-family);
  font-size: var(--text-md-regular-font-size);
  font-style: var(--text-md-regular-font-style);
  font-weight: var(--text-md-regular-font-weight);
  letter-spacing: var(--text-md-regular-letter-spacing);
  line-height: var(--text-md-regular-line-height);
  position: relative;
}

.container-footer {
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1rem;
  padding: 0px var(--frame-main-padding-right);
  position: relative;
  /* width: 95%; */
}

.play-content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  /* gap: 18px; */
  position: relative;
  width: 100%;
}

.heading-3 {
  align-self: stretch;
  color: var(--gray-900);
  font-family: var(--display-md-semibold-font-family);
  font-size: var(--display-md-semibold-font-size);
  font-style: var(--display-md-semibold-font-style);
  font-weight: var(--display-md-semibold-font-weight);
  letter-spacing: var(--display-md-semibold-letter-spacing);
  line-height: var(--display-md-semibold-line-height);
  text-align: center;
  /* position: relative; */
}

.heading-3.mobile {
  font-family: var(--display-sm-semibold-font-family);
  font-size: var(--display-sm-semibold-font-size);
  font-style: var(--display-sm-semibold-font-style);
  font-weight: var(--display-sm-semibold-font-weight);
  letter-spacing: var(--display-sm-semibold-letter-spacing);
  line-height: var(--display-sm-semibold-line-height);
}

.heading-5 {
  align-self: stretch;
  color: var(--gray-600);
  font-family: var(--text-xl-regular-font-family);
  font-size: var(--text-xl-regular-font-size);
  font-style: var(--text-xl-regular-font-style);
  font-weight: var(--text-xl-regular-font-weight);
  letter-spacing: var(--text-xl-regular-letter-spacing);
  line-height: var(--text-xl-regular-line-height);
  position: relative;
  text-align: center;
}

.heading-5.mobile {
  font-family: var(--text-lg-regular-font-family);
  font-size: var(--text-lg-regular-font-size);
  font-style: var(--text-lg-regular-font-style);
  font-weight: var(--text-lg-regular-font-weight);
  letter-spacing: var(--text-lg-regular-letter-spacing);
  line-height: var(--text-lg-regular-line-height);
}

.email-capture {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  flex-wrap: wrap;
}

.obt-input-field {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.input-with-label {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
  width: 100%;
}

.input {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cfd4dc;
  border-radius: 8px;
  box-shadow: var(--shadow-xs);
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  overflow: hidden;
  padding: 12px 14px;
  position: relative;
  width: 100%;
}

.content-5 {
  background: none;
  border: none;
  color: var(--gray-500);
  flex: 1;
  flex-grow: 1;
  font-family: var(--text-md-regular-font-family);
  font-size: var(--text-md-regular-font-size);
  font-style: var(--text-md-regular-font-style);
  font-weight: var(--text-md-regular-font-weight);
  letter-spacing: var(--text-md-regular-letter-spacing);
  line-height: var(--text-md-regular-line-height);
  margin-top: -1px;
  padding: 0;
  position: relative;
  max-width: calc(100vw - 20%);
}

.hint-text {
  align-self: stretch;
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);  
  position: relative;
}

.span {
  color: #475466;
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
}

.text-wrapper-3 {
  font-family: var(--text-sm-regular-underlined-font-family);
  font-size: var(--text-sm-regular-underlined-font-size);
  font-style: var(--text-sm-regular-underlined-font-style);
  font-weight: var(--text-sm-regular-underlined-font-weight);
  letter-spacing: var(--text-sm-regular-underlined-letter-spacing);
  line-height: var(--text-sm-regular-underlined-line-height);
  text-decoration: underline;
}

.obt-footer {
  align-items: center;
  align-self: stretch;
  justify-content: center;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  /* gap: 8px; */
  padding: 32px 0px;
  position: relative;
  width: 100%;
}

.obt-footer.mobile,
.obt-footer.tablet {
  gap: 8px;
  padding: 8px 0px 16px;
}

.heading-and-5 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  /* gap: 16px; */
  position: relative;
}

.heading-4 {
  color: var(--gray-700);
  font-family: var(--display-sm-semibold-font-family);
  font-size: var(--display-sm-semibold-font-size);
  font-style: var(--display-sm-semibold-font-style);
  font-weight: var(--display-sm-semibold-font-weight);
  letter-spacing: var(--display-sm-semibold-letter-spacing);
  line-height: var(--display-sm-semibold-line-height);
  margin: 8px 0;
  position: relative;
  text-align: center;
}

.heading-4.mobile {
  font-family: var(--display-xs-medium-font-family);
  font-size: var(--display-xs-medium-font-size);
  font-style: var(--display-xs-medium-font-style);
  font-weight: var(--display-xs-medium-font-weight);
  letter-spacing: var(--display-xs-medium-letter-spacing);
  line-height: var(--display-xs-medium-line-height);
}

.actions {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.obt-play-circle-home {
  height: 22.18px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 22.18px !important;
}

.content-6 {
  align-items: center;
  align-self: stretch;
  border-color: #eaecf0;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  padding: 32px 0px 0px;
  position: relative;
  width: 100%;
}

.store-buttons-container {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 2rem;
  position: relative;
}

.appstore-button {
  position: relative;
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  left: 1px;
  top: 1px;
  border-radius: 6px;  
}

.appstore-button.style-desktop {
  max-height: 3rem;
}

.appstore-button.style-tablet {
  max-height: 3rem;
}

.appstore-button.style-mobile {
  max-height: 3rem;
}

.appstore-button:hover {
  cursor: pointer;
}

.appstore-text {
  position: absolute;
  top: -1rem;
  visibility: none;
  opacity: 0;  
  font-family: var(--text-xl-semibold-font-family);
  font-size: var(--text-xl-semibold-font-size);
  font-style: var(--text-xl-semibold-font-style);
  font-weight: var(--text-xl-semibold-font-weight);
  letter-spacing: var(--text-xl-semibold-letter-spacing);
  line-height: var(--text-xl-semibold-line-height);
}

/* .obt-button-appstore:hover .appstore-text {
  visibility: visible;
  opacity: 1;     
} */

.home-control-wrapper.isOpen-false {
  display:none !important;
}

button.obt-button-link {
  cursor: default !important;
}