  
.card-carousel {    
  height: 25vh;
  width: 95%;
  align-self: center;
}

/* Swiper slider settings */

.card-carousel .swiper-container {
height: 100%;
max-height: 25vh;
width: 100%;
padding: 1rem 0;
position: relative;
}

.card-carousel .swiper-wrapper {  
height: 95%;
}

.card-carousel-image {
  border-radius: 5%;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 95%;
  width: 95%;
}

.card-carousel .swiper-slide-active .icon-img-after {
  display:none;
}

.card-carousel .element-card.card-state-carousel {
  border-radius: 5%;
  display: flex;
  align-items: center;
  justify-items: center;   
}

.card-carousel .element-card.card-state-carousel .card-image {  
  top: 5%;    
  border-radius: 5%;
  box-shadow: var(--shadow-carousel-card-drop-shadow);
}

.card-carousel .element-card .subheading {
width: unset !important;
margin-top: -.2rem;  
}

.card-carousel .carousel-card-instance {
border-radius: 5%;
position: relative;
height: 98%;
width: 98%;
display: flex;
justify-content: center;  
}

.card-carousel .swiper-slide-shadow-left,
.card-carousel .swiper-slide-shadow-right {
display: none;
}

.card-carousel .swiper-button-prev::after {
  content: url(../../../public/img/left-arrow-nav.png);
  padding: 0 1rem;
}

.card-carousel .swiper-button-next::after {
  content: url(../../../public/img/right-arrow-nav.png);
  padding: 0 1rem;
}


.card-carousel .slider-controller {
position: relative;
height:5%;
margin-top: .5rem;
display: flex;
align-items: center;
justify-content: center;
}

.card-carousel .swiper-pagination {
  position: relative !important;
  margin-top: 0.5rem;
  scale: 1.5;
}

.card-carousel .swiper-pagination .swiper-pagination-bullet {
background: var(--primary-400);
/* filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1)); */
}

.card-carousel .swiper-pagination .swiper-pagination-bullet-active {
background: var(--primary-500);
}

.card-carousel .element-card .image-overlay {
font-size: calc(var(--card-width-large)/3);
}