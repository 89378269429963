.obt-hero-header {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  left: 20px;
  position: relative;
}

.obt-hero-header .heading-wrapper {
  flex-direction: column;
  position: relative;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  display: flex;
}

.obt-hero-header .text-wrap {
  position: relative;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
}

.obt-hero-header .overlap-group-3 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -1px;
}

.obt-hero-header .heading {
  color: var(--gray-900);
  top: 0;
}

.obt-hero-header .underline {
  position: relative;
  z-index: 0;
}

.obt-hero-header .underline-desktop {
  position: relative;
  top: -1rem; 
  max-width: 50%;
  padding: 0px 0px 0px 4%;
  z-index: 0;
}
.obt-hero-header.obthelps .underline-desktop {
  right: 1rem;
}

.obt-hero-header .underline-tablet {
  position: relative;
  top: -.5rem; 
  max-width: 45%;
  padding: 0px 0px 0px 2%;
  z-index: 0;
}
.obt-hero-header.obthelps .underline-tablet {
  max-width: 40%;
  right: 1rem;
}

.obt-hero-header .underline-mobile {
  position: relative;
  top: -8px;
  max-width: 40%;
  padding: 0px 0px 0px 5%;
  z-index: 0;
}
.obt-hero-header.obthelps .underline-mobile {
  max-width: 37%;
  right: 1rem;
}

.obt-hero-header.breakpoint-1-mobile .heading {
  color: var(--gray-900);
  font-family: var(--display-xs-medium-font-family);  
  font-size: var(--display-xs-medium-font-size);
  font-weight: var(--display-xs-medium-font-weight);  
  letter-spacing: var(--display-xs-medium-letter-spacing);
  line-height: var(--display-xs-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  z-index: 2;
}

.obt-hero-header .supporting-text {
  color: var(--gray-600);
  position: relative;
  margin-block: 0;
  display:flex;
  flex-shrink: 1;
  flex-wrap: wrap;
  justify-content: center;
}

.obt-hero-header.breakpoint-1-mobile .container {
  align-items: flex-start;
  align-self: stretch;
  justify-self: stretch;
  display: flex;
  gap: 24px;
  justify-content: center;
  width: 100%;
}

.obt-hero-header.breakpoint-1-desktop .container {
  align-items: center;
  display: flex;
  gap: 32px;
  justify-content: center;
  width: 738px;
}

.obt-hero-header.breakpoint-1-tablet .container {
  align-items: flex-end;
  display: inline-flex;
  justify-content: center;
  gap: 24px;
}

.obt-hero-header.breakpoint-1-mobile .heading {
  font-family: var(--display-sm-medium-font-family);
  font-size: var(--display-sm-medium-font-size);
  font-weight: var(--display-sm-medium-font-weight);  
  letter-spacing: var(--display-sm-medium-letter-spacing);
  line-height: var(--display-sm-medium-line-height);
  left: 6px;
}

.obt-hero-header.breakpoint-1-desktop .heading {
  font-family: var(--display-xl-medium-font-family);
  font-size: var(--display-xl-medium-font-size);
  font-style: var(--display-xl-medium-font-style);
  font-weight: var(--display-xl-medium-font-weight);  
  letter-spacing: var(--display-xl-medium-letter-spacing);
  line-height: var(--display-xl-medium-line-height);
  left: 6px;
}

.obt-hero-header.breakpoint-1-tablet .heading {
  font-family: var(--display-md-medium-font-family);  
  font-size: var(--display-md-medium-font-size);
  font-weight: var(--display-md-medium-font-weight);  
  letter-spacing: var(--display-md-medium-letter-spacing);
  line-height: var(--display-md-medium-line-height);
  left: 0;
  white-space: nowrap;
}

.obt-hero-header.breakpoint-1-mobile .supporting-text {
  font-family: var(--text-xxs-medium-font-family);
  font-size: var(--text-xxs-medium-font-size);
  font-style: var(--text-xxs-medium-font-style);
  font-weight: var(--text-xxs-medium-font-weight);
  letter-spacing: var(--text-xxs-medium-letter-spacing);
  line-height: var(--text-xxs-medium-line-height);
}

.obt-hero-header.breakpoint-1-desktop .supporting-text {
  font-family: var(--text-lg-regular-font-family);
  font-size: var(--text-lg-regular-font-size);
  font-style: var(--text-lg-regular-font-style);
  font-weight: var(--text-lg-regular-font-weight);
  letter-spacing: var(--text-lg-regular-letter-spacing);
  line-height: var(--text-lg-regular-line-height);
  white-space: nowrap;
  /* width: fit-content; */
}

.obt-hero-header.breakpoint-1-tablet .supporting-text {  
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  align-self: stretch;
}
