.obtextras {
  align-items: flex-start;
  /* border-radius: 6px; */
  /* box-shadow: 0px 4px 4px #00000040; */
  display: flex;
  position: relative;
  /* width: 1224px; */
}

.obtextras .frame-extras {
  align-items: flex-start;
  /* background: linear-gradient(180deg, rgba(144, 168, 40, 0.18) 0%, rgba(144, 168, 40, 0) 100%); */
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  min-height: var(--breakpoints-minbookheight);
  padding: var(--frame-main-padding);
  position: relative;  
  align-content: flex-start;
  justify-content: center;
}

.obtextras.story.small {
  gap: 10px;
}

.obtextras.small .frame-extras {
  gap: 48px 48px;
}

.obtextras.traditional .frame-extras {
  overflow: hidden;
}

.obtextras.large .frame-extras {
  gap: 48px 48px;
  overflow: hidden;

}

.obtextras.story.small .frame-extras {
  align-self: stretch;
}
