.resource-movements {
  display:grid;
  overflow: visible;
  /* box-shadow: 0px 1px 1px #00000040;   */
}

.resource-movements .frame-resource-movements {  
  display: flex;
  flex: 0;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  position: relative;  
  align-content: flex-start;
  justify-content: center;
  /* overflow: hidden; */
  border-radius: 6px;  
  padding: 1rem 0 1rem var(--resource-card-width-small);
  width: 95%;
}

.resource-movements.small .frame-resource-movements {
  gap: 0.5rem 0.5rem;
}


.frame-resource-movements .skeleton-box  {
  width: 50% !important;
  overflow: hidden;
}