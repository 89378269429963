/* .books {
  align-items: flex-start;
  border-radius: 6px;
  display: flex;
  position: relative;
} */
.books {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 6px;
  /* box-shadow: 0px 4px 4px #00000040; */
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.books .frame-books {
  align-items: flex-start;
  /* background: linear-gradient(180deg, rgba(144, 168, 40, 0.18) 0%, rgba(144, 168, 40, 0) 100%); */
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  min-height: var(--breakpoints-minbookheight);
  padding: var(--frame-main-padding);
  position: relative;  
  align-content: flex-start;
  justify-content: center;
}

.books.story.small {
  gap: 10px;
}

.books.small .frame-books {
  gap: 48px 48px;
}

.books.large .frame-books {
  gap: 48px 48px;
  overflow: hidden;

}

.books.traditional .frame-books {
  overflow: hidden;
}

.books.story.small .frame-books {
  align-self: stretch;
}

.error-message {
  border-width: 1px;
  border-color: var(--gray-500);
  box-shadow: var(--shadow-APM-drop-shadow);
  padding: 4rem;
  margin: 2rem;
  text-align: center;
  width: 50%;
}