.obt-nav-menu-support {
  background-color: var(--basewhite);
  align-items: flex-start;
  display: flex;
  gap: 16px;
  padding: 2px;
  position: absolute;
  flex-wrap: wrap;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: all var(--speed) ease-out;
}

.obt-nav-menu-support .content {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  position: relative;
  flex-wrap: wrap;
}

.obt-nav-menu-support .icon {
  height: 24px !important;
  /* position: relative !important; */
  width: 24px !important;
}

.obt-nav-menu-support .text-and-supporting {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  gap: 4px;
  position: relative;
  max-width: 100%;
  text-wrap: wrap;
}

.obt-nav-menu-support .text-and-badge {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.obt-nav-menu-support .text {
  color: var(--gray-900);
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-icon-wrapper {
  display: grid;
  grid-template-columns: 1fr 4fr;
  justify-items: space-between;
  gap: 12px;
  width: 100%;
}

.obt-nav-menu-support .supporting-text {
  align-self: stretch;
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  position: relative;
}

.obt-nav-menu-support img:hover {
  cursor: pointer;
}


.obt-nav-menu-support .text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.support-menu-item:hover .obt-nav-menu-support {
  overflow: visible;
  visibility: visible;
  opacity: 1;
  border-color: var(--gray-200);
  border-width: 1px;
  box-shadow: var(--shadow-drop-shadow);
  transition: all var(--speed) ease-in;
}