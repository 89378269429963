.obt-playlist-add {
  position: relative;
  display: inline-flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  align-self: center;
  transition: .4ms ease-in-out;
}

.obt-playlist-add.section-playlist-add {
  background-color:var(--apm-project-blue50-50pct );
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  overflow: hidden;
  top: 1%;
  left: 1%;
  transition: .4ms ease-in-out;
}

.obt-playlist-add:hover {
  overflow: visible;
  cursor: pointer;
  transform: scale(1.2);
}

/* .obt-playlist-add-icon:hover {
  cursor: pointer; 
  transform: scale(1.2);
} */

.obt-playlist-add-icon {  
  height: 20px;
  width: 20px;
}

.obt-playlist-add-frame {
  align-items: center;
  justify-items: center;  
}

.obt-playlist-add .icon-frame {
  align-items: center;
  justify-items: center;
  max-width: 28px;
  overflow: visible;
  padding: 0 2px;
  position: relative;
}

.obt-playlist-add .tooltip {  
  /* position: relative; */
  position: absolute;
  white-space: nowrap;
  text-align: center;
  align-self: center;
  overflow: visible;   
  z-index: 2;
  bottom: 100%;
  left: -100%;  
  visibility: hidden;
}

.obt-playlist-add .icon-frame:hover .tooltip  {
  visibility: visible;
}

