  .audioplaylist {
    display: flex; 
    flex-direction: column;
    flex: 0 0 auto;
    width: 100%;
    justify-content: center;
  }

  .play-list-card {
      display: flex; 
      flex-direction: row;
      flex: 0 0 auto;
      width: 100%;
      justify-content: center;
    }
  
  .play-list-card .frame-card {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    width: 100%;
  }

  .play-list-card .frame-card:hover {
    box-shadow: var(--shadow-list-card-drop-shadow);
    background: var(--primary-50);
    cursor: pointer;
  }

  /* Active card is larger */
  .play-list-card.audio-playlist-instance.active-track {
    background: var(--primary-50);
    box-shadow: var(--shadow-list-card-drop-shadow);
    /* transform-origin: center;     */
    transform: translate(12%) scale(1.3) ;
    padding: 0.2rem;
    margin: 0.2rem;
    width: 80%;
  }

  .play-list-card .frame-image {
    display: inline-flex;    
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    width: 4rem;
    height: auto;   
    position: relative;
    margin: 0.1rem;
    box-shadow: var(--shadow-audio-card-drop-shadow);
  }
   
  .play-list-card .frame-title {
    display: flex;    
    flex: 1 1 auto;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 0rem 0rem 0.2rem;
    position: relative;    
    width: 100%;
  }
  
  .play-list-card .card-text {
    color: var(--gray-700);
    font-family: var(--text-xs-medium-font-family);
    font-size: var(--text-xs-medium-font-size);
    font-style: var(--text-xs-medium-font-style);
    font-weight: var(--text-xs-medium-font-weight);
    letter-spacing: var(--text-xs-medium-letter-spacing);
    line-height: var(--text-xs-medium-line-height);
    position: relative;
    justify-content: flex-start;
  }

  .play-list-card .card-subtext {
    font-size: var(--text-xxs-regular-font-size);
    font-weight: var(--text-xxs-regular-font-weight);
    line-height: 120%;
    color: var(--gray-400);    
  }

  .play-list-card .frame-title .title-icon {
    position: relative;
    justify-content: flex-end;
    height: 1rem;
    width: 1rem;
    z-index: 2;
  }

  .play-list-card .frame-card .playlist-delete-icon {
    position: absolute;
    /* top: 0.5rem; */
    height: 24px;
    width: 24px;
    right: 2rem;
    z-index: 4;
    opacity: 0;
    transition: fill 0.2s ease-in-out;
  }

  .play-list-card .frame-card:hover {
    .playlist-delete-icon {  
      background-color: white;
      opacity: 1;
    }
  }
  

  /* For placeholder cards during loading */
  .player-skeleton-box  {
    width: 100%;    
    overflow: hidden;    
  }

  .player-skeleton-box .play-list-card .card-image {
    opacity: 0.1;    
    /* z-index: 0; */
  }

  .player-skeleton-box .play-list-card .title-icon {
    display:none;    
  }

  .player-skeleton-box .play-list-card .card-name,
  .player-skeleton-box .play-list-card .card-subtext {     
    color: var(--gray-200);
    margin: 0.5rem 1rem 0rem 0.5rem;      
  }

  .frame-image.shimmer {    
    height: 3rem;
  }

  
