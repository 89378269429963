.resources {
  position: relative;
  border-radius: 6px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  align-self: stretch;
  overflow: visible;
  width: 100%;
}

.resources .frame-resources {
  align-items: flex-start;
  /* background: linear-gradient(180deg, rgba(144, 168, 40, 0.18) 0%, rgba(144, 168, 40, 0) 100%); */
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  min-height: var(--breakpoints-minbookheight);
  position: relative;  
  align-content: flex-start;
  justify-content: flex-start;
  margin: 5% 0 5% 10%;
  /* overflow: hidden; */
  width: 90%;
}

.resources.story.small {
  gap: 10px;
}

.resources.small .frame-resources {
  gap: 36px 48px;
  /* overflow: hidden; */
}

.resources.large .frame-resources {
  /* gap: 48px 48px; */
  /* overflow: hidden; */
}

.resources.traditional .frame-resources {
  overflow: hidden;
}

.resources.story.small .frame-resources {
  align-self: stretch;
}

.error-message {
  border-width: 1px;
  border-color: var(--gray-500);
  box-shadow: var(--shadow-APM-drop-shadow);
  padding: 4rem;
  margin: 2rem;
  text-align: center;
  width: 50%;
}