:root {
  --resource-level2-height-small: 40px;
  --resource-level2-width-small: 40px;
}

.resource-level2 {
  align-items: center;
  display: inline-flex;
  gap: 2rem;
  justify-content: left;
  min-height: 20px;    
  position: relative;
  margin: 0 0.5rem;
  background-color: white;
  overflow:visible;
  /* box-shadow: 0px 1px 1px #00000040; */
}

/* .resource-level2:hover {
   cursor: pointer;
   box-shadow: 0px 4px 4px #00000040;
   background-color: var(--primary-50);
} */

.resource-level2.card-mode-small {
  width: 99%;
  /* max-width: 99%;
  min-width: 99%; */
}

.resource-level2.card-mode-small:hover {
  /* cursor: pointer; */
   box-shadow: 0px 4px 4px #00000040;
   background-color: var(--primary-50);
}

.resource-level2 .level2-frame-card {
  align-items: flex-start;    
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;    
  width: 100%;
}

.resource-level2.card-mode-mini .level2-frame-card {
  box-shadow: var(--shadow-audio-card-drop-shadow);
}

.resource-level2 .level2-frame-overlay {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-right:1.5rem;
  width: 100%;
  gap: 0.5rem;
}

.resource-level2 .level2-frame-title {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: left;
  width: 100%;
}

.resource-level2 .level2-frame-title.shimmer {
  color: var(--gray-100);
}

.resource-level2 .level2-frame-image {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  box-shadow: var(--shadow-audio-card-drop-shadow);
}

/* .resource-level2 .frame-image.shimmer {    
  height: 3rem;
} */

.resource-level2 .level2-card-name {
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);  
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);  
}

.resource-level2 .resource-level2-image {
  height: var(--resource-level2-height-small);
  width: var(--resource-level2-width-small);
  object-fit: cover;
  position: relative;  
}

.resource-level2 .level2-expand-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-items: right;
  margin-right: 1.5rem;
  gap: 0.5rem;
  color: var(--gray-700);
  z-index: 3;
}

.resource-level2 .level2-expand-resource {
  width: 24px;
  height: 24px;
}

.resource-level2 .level2-resource-download {
  width: 18px;
  height: 18px;
  z-index: 4;
  position: relative;
  overflow:visible;
  background-color: transparent;
}

.resource-level2 .level2-resource-quickplay {
  width: 24px;
  height: 24x;
  z-index: 4;
}

.resource-level2 .level2-resource-download:hover {
  background-color: transparent;
  box-shadow:none;
  cursor: pointer;
  transform: scale(1.2);
}

