.display-message {
  align-self: center;
  text-align: left;
  align-items: stretch;
  position: relative;
  background-color: var(--basewhite);
  border: 1px solid;
  border-radius: 6px;
  border-color: var(--gray-200);
  box-shadow: var(--shadow-drop-shadow);
  margin: 2rem;
}

.display-message .display-content {
  align-items: flex-start;
  /* text-align: justify; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  position: relative;
  width: 100%;
}

.display-content .title {
  color: var(--gray-500);
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  align-self: center;
}

.display-message .description {
  align-self: center;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  margin-right: -2px;
  position: relative;
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
}

.display-message .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 290px;
  text-wrap: wrap;
}
