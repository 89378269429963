.playlist-add-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 9;
}

.playlist-add-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--primary-50);
    padding: 1rem;
    z-index: 10;
    max-height: 90vh;
    overflow-y: auto;
  }

.close-playlist-add-modal {
  display: flex;
  justify-content: flex-end;
}  

.playlist-add-gradient-frame {
  align-items: flex-start;
  background: linear-gradient(180deg,rgba(176, 200, 232, .50) 0%, rgba(144, 168, 40, 0.20) 100%);
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* height: 632px; */
  padding: 1rem;
  position: relative;
  /* width: 455px; */
}

.playlist-add-gradient-frame .header-frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.playlist-add-gradient-frame .text-wrapper {
  align-self: stretch;
  color: var(--gray-700);
  font-family: var(--display-xs-semibold-font-family);
  font-size: var(--display-xs-semibold-font-size);
  font-style: var(--display-xs-semibold-font-style);
  font-weight: var(--display-xs-semibold-font-weight);
  letter-spacing: var(--display-xs-semibold-letter-spacing);
  line-height: var(--display-xs-semibold-line-height);    
  margin-top: -1px;
  position: relative;
  text-align: center;
}

/* Spinner styles */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db; /* Spinner color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}