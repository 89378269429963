.audio-controls-container {
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  grid-template-rows: 1;
  position: fixed;  
  bottom: 0;
  left: 0;
  width: 100vw;
  background: var(--primary-50);
  overflow:hidden;
  z-index: 10;
}

.audio-controls-container.sOpen-false {
  display: none;
}

.audio-controls-container.tablet {
min-width: var(--breakpoints-width);
}

.audio-controls-container.mobile {
  min-width: var(--breakpoints-width);
  grid-template-columns: 1fr 1fr 1fr;

}

button {
  background: none;
  border: none;
  cursor: pointer;
}

.audio-controls-center {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0.5rem 0 0.5rem 0;
}

.audio-controls-right {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: right;
  flex-wrap: wrap;
  margin: 0.5rem 1rem 0.5rem 0;
}

.tablet .audio-controls-right,
.mobile .audio-controls-right {
  /* margin: 4px; */
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin: 0.5rem 0 0.5rem 0;
}

.audio-controls-left {
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: row;
  align-content: center;
  justify-content: left;
  margin: 0.5rem;
  overflow: hidden;
  gap: 4px;
}

.time-controls {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  gap: 1rem;
}

.audio-controls {
  display: flex;
  justify-content: center;
  gap: 2rem;
  width:  100%;
}


.audio-controls-container.tablet .audio-controls {
  gap: 4px;
}
.audio-controls-container.mobile .audio-controls {
  gap: 0px;
}

.audio-controls .prev svg,
.audio-controls .next svg {
  width: 35px;
  height: 35px;
}

.audio-controls .rewind svg,
.audio-controls .forward svg {
  width: 30px;
  height: 30px;
}

.audio-controls .play svg,
.audio-controls .pause svg {
  height: 40px;
  width: 40px;
}

.title-text {
  color: var(--gray-700);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  max-height: 4rem;
  align-self: center;
}

.audio-controls-left .frame-image {
  display: inline-flex;    
  align-items: center;
  justify-content: center;
  padding: 0.25rem;  
  height: auto;
  min-width: 3rem;
  max-width: 3rem;
  position: relative;
  margin: 0.1rem;
  box-shadow: var(--shadow-audio-card-drop-shadow);
}

.time-text {
  color: var(--gray-700);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
}

  /* For playback progress bar */
.time-controls input[type=range] {
	height: 5px;
	-webkit-appearance: none; /* remove default appearance on Webkit-based browsers */
  -moz-appearance: none; /* remove default appearance on Mozilla-based browsers */
  appearance: none; /* remove default appearance on all other browsers */
	width: 88%;  
	margin: 0.5rem 0;
	border-radius: 8px;
	background: var(--primary-500);
	transition: background 0.2s ease;
	cursor: pointer;  
}

.time-controls input[type=range].progress {
  accent-color: var(--primary-500);
}


.time-controls input[type=range].progress-disabled {
  accent-color: var(--gray-300);
}

.time-controls.inactive input[type=range],
.audio-controls-center.inactive button {
  cursor: default;
}

.pause-play-icon.loading {
    opacity: 50%;
    animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
      transform: scale(1) rotate(0deg);
  }
  to {
      transform: scale(1) rotate(360deg);
  }
}

