
.book-carousel {    
    height: calc(100vh / 3);
    width: 95%;
    align-self: center;
}

/* Swiper slider settings */

.book-carousel .swiper-container {
  /* padding: 8px 0;
  height: 95%; */
  height: 100%;
  width: 100%;
  padding: 1rem 0;
  position: relative;
}

.book-carousel .swiper-wrapper {  
  height: 95%;
}

.book-carousel .swiper-button-prev::after {
  content: url(../../../public/img/left-arrow-nav.png);
  padding: 0 1rem;
}

.book-carousel .swiper-button-next::after {
  content: url(../../../public/img/right-arrow-nav.png);
  padding: 0 1rem;
}

.book-carousel-image {
    border-radius: 5%;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 95%;
    width: 95%;
}

.book-carousel .swiper-slide-active .icon-img-after {
  display:none;
}

.book-carousel .element-card.card-state-carousel {
    border-radius: 5%;
    display: flex;
    align-items: center;
    justify-items: center;   
}

.book-carousel .element-card.card-state-carousel .card-image {  
    top: 5%;    
    border-radius: 5%;
    box-shadow: var(--shadow-carousel-card-drop-shadow);
}

.book-carousel .element-card .subheading {
  width: unset !important;
  margin-top: -.2rem;  
}

.book-carousel .carousel-card-instance {
  border-radius: 5%;
  position: relative;
  height: 98%;
  width: 98%;
  display: flex;
  justify-content: center;  
}

/* @media (max-width: 500px) {
  .swiper_container {
    height: 18rem;
  }
  .swiper-slide {
    width: 12rem !important;
    height: 17rem !important;
  }
} */

.book-carousel .swiper-slide-shadow-left,
.book-carousel .swiper-slide-shadow-right {
  display: none;
}

.book-carousel .slider-controller {
  position: relative;
  height:5%;
  margin-top: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-carousel .swiper-pagination {
    position: relative !important;
    margin-top: 0.5rem;
    scale: 1.5;
}

.book-carousel .swiper-pagination .swiper-pagination-bullet {
  background: var(--primary-400);
  /* filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1)); */
}

.book-carousel .swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary-500);
}

