  
  .audio-playlist {
    /* background: radial-gradient(50% 50% at 50% 50%, rgba(176, 200, 232, 0) 0%, rgba(176, 200, 232, 0.5) 99.48%); */
    border-radius: 6px;
    display: flex;
    flex-flow: column;
    position: relative;
    justify-items: center;
    padding: 4px;
    width: 100%;
    height: 100%;
    gap: 0.5em;
    overflow-x: hidden;
    overflow-y: auto;
  }
    
  .audio-playlist .header-frame {
    align-items: center;
    border-radius: 6px;
    display: flex;
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 10px;
    height: 29px;
    justify-content: center;
    padding: 2px 6.5px;
    position: relative;
    width: 95%;
  }

  .audio-playlist-header-text {
    justify-self: center;
    color: var(--gray-700);
    font-family: var(--display-xs-medium-font-family);
    font-size: var(--display-xs-medium-font-size);
    font-style: var(--display-xs-medium-font-style);
    font-weight: var(--display-xs-medium-font-weight);
    letter-spacing: var(--display-xs-medium-letter-spacing);
    line-height: var(--display-xs-medium-line-height);
  }
  
  .audio-playlist .frame-play {
    align-self: center;
    align-items: flex-start;
    display: inline-flex;
    flex-flow: column;
    flex: auto;    
    gap: 10px;
    position: relative;
    /* min-width: calc(max(95%, var(--playlist-width))); */
    min-width: 95%;
    max-height: calc(100vh - 120px);
  } 
  .audio-playlist .clarity-pop-out-line {
    height: 15px;
    position: relative;
    width: 15px;
  }
  
  .audio-playlist svg.expand-player,
  .audio-playlist svg.popout-player {
    height: 20px;
    width: 20px;
    margin-right: 4px;
  }

  .audio-playlist-clear-all  {
    justify-self: right;
    font-family: var(--display-xxs-regular-font-family);
    font-size: 12px;
    font-style: var(--display-xxs-regular-font-style);
    font-weight: var(--display-xxs-regular-font-weight);
    letter-spacing: var(--display-xxs-regular-letter-spacing);
    line-height: var(--display-xxs-regular-line-height);
    text-decoration-line: underline;
  }

  .audio-playlist-clear-all:hover {
    color: var(--primary-700);
    cursor: pointer;
  }