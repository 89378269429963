.audio-category-card {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  /* min-height: 100vh; */
  position: relative;
}

.audio-category-card .frame-card {
  align-items: flex-start;
  border-radius: 5px;
  box-shadow: var(--shadow-lg);
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 64px;
  overflow: hidden;
  position: relative;
  width: 205px;
}

.audio-category-card .frame-background {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  position: relative;
  width: 100%;
}

.audio-category-card .frame-overlay {
  align-items: center;
  align-self: stretch;  
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.audio-category-card .image-frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.audio-category-card .image {
  height: 43.62px;
  object-fit: cover;
  position: relative;
  width: 43.62px;
}

.audio-category-card .frame {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  padding: 5px;
  position: relative;
}

.audio-category-card .card-name {
  color: #000000;
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  position: relative;
  width: 107.72px;
}

.audio-category-card .frame-icon {
  flex: 0 0 auto;
  position: relative;
}

.audio-category-card .frame-toggle {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  justify-content: space-around;
  position: relative;
  width: 2rem;
}

.audio-category-card .obt-toggle-instance {
  flex: 0 0 auto !important;
}

.audio-category-card .obt-toggle-instance {
  box-shadow: 0px 4px 4px #00000040 !important;
}

.audio-category-card .title-icon {
  align-content: flex-end;
  align-self: center;
  z-index: 2;
}

/* For placeholder cards during loading */
.skeleton-box  {
  width: unset !important;
  overflow: hidden;
}

.skeleton-box .audio-category-card .image {
  opacity: 0;    
}

.skeleton-box .audio-category-card .title-icon {
  display:none;    
}

.skeleton-box .audio-category-card .frame {  
  opacity: 0;
}

.skeleton-box .audio-category-card .card-name {
  display: none;  
}