
.publishing-menu {
  align-items: flex-start;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 4;
}

.desktop .publishing-menu,
.tablet .publishing-menu {
  top: 120%;
  left: 0;
}
.mobile .publishing-menu {
  top: 50%;
  right: 110%;  
}

.publishing-menu .content-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  position: relative;
  width: 100%;
}

.publishing-menu .akuo-frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 5px;
  position: relative;
  width: 100%;
}

.publishing-menu .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
}

.publishing-menu .akuo-icon {
  height: 30px;
  object-fit: cover;
  position: relative;
  width: 29.64px;
}

.publishing-menu .obt-nav-menu-item-instance {
  flex: 1 !important;
  flex-grow: 1 !important;
  left: unset !important;
  top: unset !important;
  width: unset !important;
}

