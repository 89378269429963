.obt-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  padding: 10px 0px;  
  align-items: center;
  align-self: stretch;
  position: relative;
  padding-top: 8px;
}

/* .obt-header-navigation-menu {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  padding: 15px 20px ;
  z-index: 1;
}  */

@supports (display: grid) {
  .obt-header.desktop,
  .obt-header.tablet {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr;
  }

  .obt-header.mobile {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr;
  }

  .obt-header.mobile .obt-icon-wrap-wrapper {
      grid-column: 1 / span 1;
      grid-row: 1;
  }
  
  .obt-header.mobile .mobile-appstore-wrapper {
    grid-column: 2 / span 2;
    grid-row: 1;
  }

  .obt-header.mobile .mobile-menu-wrapper {
      grid-column: 4 / span 1;
      grid-row: 1;
  }
  
}

/* @supports not (display: grid) {
  .obt-header.desktop,
  .obt-header.tablet {  
    display: flex; 
  }

} */

.obt-header .burger-frame {
  align-items: center;
  display: var(--breakpoints-showhamburger, flex);
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: calc(50% - 241px);
  padding: 0px 10px;
  position: absolute;
  top: 20px;
  width: 30px;  
}

.obt-header .mobile-menu-wrapper {
  align-items: center;  
  justify-content: right;
  justify-self: right;
  padding: 0px 10px;
}


.obt-header .obt-icon-wrap-wrapper {
  align-items: center;
  display: inline-flex;
  padding: 0px 10px;
  /* position: absolute; */
}
.obt-header.mobile .obt-icon-wrap-wrapper {
  left: 16px;
  top: 19px;
}

.obt-header .mobile-appstore-wrapper {
  display: inline-flex;
  align-items: center;  
  justify-content: center;
}
.obt-header.mobile .appstore-button.style-header-mobile {
  right: 0;
  padding: 0;
}

.obt-header .right-nav-wrapper {  
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
}

p.beta-text {
  margin-block-start: 0em !important;
  margin-block-end:  0em !important;
  color: var(--gray-400);
  font-family: var(--display-lg-medium-font-family);
  font-size: var(--display-lg-medium-font-size);
  font-style: var(--display-lg-medium-font-style);
  font-weight: var(--display-lg-medium-font-weight);
  letter-spacing: var(--display-lg-medium-letter-spacing);
  line-height: var(--display-lg-medium-line-height);
}

.mobile-nav-menu {
  background-color: var(--gray-50);
  box-shadow: var(--shadow-APM-drop-shadow);
  position: absolute;
  right: 2rem;
  top: 2.75rem;
  z-index: 5;
  display: flex;
  flex-direction: column;
  /* padding: 1rem 2rem 1rem 0; */
}

.mobile-nav-menu .obt-menu-text {
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
}

.mobile-nav-menu.active{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.mobile-nav-menu.inactive{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}

.store-buttons-container-mobile {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}

.appstore-button {
  width: 8rem;
  justify-self:flex-end;
}

.appstore-button.style-header-desktop {
  width: 8rem;
  justify-self:flex-end;
}
.appstore-button.style-header-tablet {
  scale: 80%;
  justify-self:flex-end;
}
.appstore-button.style-header-mobile {
  scale: 70%;
  justify-self:flex-end;
}
