.obt-quick-play {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: .4ms ease-in-out;
}

.obt-quick-play:hover {
  cursor: pointer; 
  transform: scale(1.2);
}

.obt-quick-play .obt-play-circle {  
  height: 1.5rem;
  width: 1.5rem;  
}

.obt-quick-play .frame {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  padding: 2px;
}

.obt-quick-play .text-wrapper {
  color: var(--apm-project-blue700);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-font-size);
  background-color: var(--gray-300);
  text-shadow: 0px 1px 1px var(--apm-project-blue300);
  border-radius: 1px;  
  position: relative;
  white-space: nowrap;
  width: fit-content;
  /* text-align: left; */
}

