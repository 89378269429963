:root {
  /* Custom settings for akuo and obthelps */
  --playlist-width: 300px;


  /* Set site-wide font stack  */
  --primary-font-family: 'Inter', system-ui; 

  /* Colors */
  --apm-dark-green10: rgba(247, 250, 234, 1);
  --apm-dark-green100: rgba(216, 231, 152, 1);
  --apm-dark-green200: rgba(209, 226, 131, 1);
  --apm-dark-green25: rgba(240, 245, 214, 1);
  --apm-dark-green300: rgba(201, 221, 110, 1);
  --apm-dark-green400: rgba(193, 216, 90, 1);
  --apm-dark-green450: rgba(185, 211, 69, 1);
  --apm-dark-green50: rgba(224, 236, 172, 1);
  --apm-dark-green500: rgba(178, 207, 48, 1);
  --apm-dark-green600: rgba(160, 186, 44, 1);
  --apm-dark-green700: rgba(144, 168, 40, 1);
  --apm-dark-green750: rgba(142, 165, 39, 1);
  --apm-dark-green800: rgba(124, 145, 34, 1);
  --apm-dark-green850: rgba(107, 124, 29, 1);
  --apm-dark-green900: rgba(53, 62, 15, 1);
  --apm-dark-green950: rgba(18, 21, 5, 1);
  --apm-light-green10: rgba(248, 251, 234, 1);
  --apm-light-green100: rgba(233, 242, 192, 1);
  --apm-light-green200: rgba(226, 237, 171, 1);  /* #E2EDAB */
  --apm-light-green300: rgba(219, 233, 150, 1);
  --apm-light-green400: rgba(212, 228, 129, 1);
  --apm-light-green50: rgba(241, 246, 213, 1);
  --apm-light-green500: rgba(204, 224, 108, 1);
  --apm-light-green550: rgba(197, 219, 87, 1);
  --apm-light-green600: rgba(190, 215, 66, 1);
  --apm-light-green650: rgba(185, 212, 53, 1);
  --apm-light-green700: rgba(183, 210, 45, 1);
  --apm-light-green750: rgba(164, 189, 40, 1);
  --apm-light-green800: rgba(146, 168, 36, 1);
  --apm-light-green900: rgba(110, 126, 27, 1);
  --apm-light-green950: rgba(55, 63, 13, 1);
  --apm-project-blue10: rgba(249, 251, 254, 1);
  --apm-project-blue100: rgba(216, 228, 243, 1);
  --apm-project-blue200: rgba(176, 200, 232, 1);  /* #B0C8E8*/
  --apm-project-blue25: rgba(240, 244, 250, 1);
  --apm-project-blue300: rgba(137, 173, 220, 1);
  --apm-project-blue400: rgba(98, 146, 208, 1);
  --apm-project-blue50: rgba(235, 241, 249, 1);
  --apm-project-blue50-50pct: rgba(235, 241, 249, .5);
  --apm-project-blue500: rgba(66, 124, 199, 1);
  --apm-project-blue600: rgba(53, 106, 177, 1);
  --apm-project-blue700: rgba(41, 83, 137, 1);  /* #295389 */
  --apm-project-blue800: rgba(35, 71, 118, 1);
  --apm-project-blue900: rgba(23, 47, 79, 1);
  --apm-project-blue950: rgba(12, 24, 39, 1);
  --baseblack: rgba(0, 0, 0, 1);
  --basewhite: rgba(255, 255, 255, 1);  /* #FFFFFF */
  --breakpoints-halftheight: 465px;
  --breakpoints-height: 935px;
  --breakpoints-minbookheight: 800px;
  --breakpoints-padding: 12px;
  --breakpoints-showhamburger: none;
  --breakpoints-showmenuitems: initial;
  --breakpoints-width: 1064px;
  --focus-ring-4px-primary-100: 0px 0px 0px 4px rgba(244, 235, 255, 1);
  --general-audiocardcurrent: rgba(255, 255, 255, 0.7);
  --general-breadcrumbs-audiorownum: 0px;
  --general-breadcrumbs-breadcrumbshow-1: none;
  --general-breadcrumbs-breadcrumbshow-2: none;
  --general-breadcrumbs-breadcrumbshow-3: none;
  --general-breadcrumbs-breadcrumbshow-4: none;
  --general-cards-background-download: rgba(234, 236, 240, 1);
  --general-cards-background-frame-1: var(--general-cards-background-download);
  --general-cards-background-frame-2: var(--general-cards-background-download);
  --general-cards-background-frame-3: var(--general-cards-background-download);
  --general-cards-background-frame-4: var(--general-cards-background-download);
  --general-cards-background-frame-5: var(--general-cards-background-download);
  --general-cards-background-normal: rgba(255, 255, 255, 1);
  --general-cards-colorconnection: rgba(240, 171, 251, 1);
  --general-cards-colorculturalexpectation: rgba(255, 194, 194, 1);
  --general-cards-colordevotional: rgba(178, 253, 212, 1);
  --general-cards-colorintroduction: rgba(251, 243, 171, 1);
  --general-cards-colorteamqa: rgba(255, 181, 95, 1);
  --general-cards-colortranslationdecision: rgba(184, 247, 255, 1);
  --general-cards-showdownload-1: initial;
  --general-cards-showdownload-2: initial;
  --general-cards-showdownload-3: initial;
  --general-cards-showdownload-4: initial;
  --general-cards-showdownload-5: initial;
  --general-cards-showprogress-1: none;
  --general-cards-showprogress-2: none;
  --general-cards-showprogress-3: none;
  --general-cards-showprogress-4: none;
  --general-cards-showprogress-5: none;
  --general-cards-showprogress-6: none;
  --general-cards-showprogress-7: none;
  --general-cards-showprogress-8: none;
  --general-cards-showstatus-1: none;
  --general-cards-showstatus-2: none;
  --general-cards-showstatus-3: none;
  --general-cards-showstatus-4: none;
  --general-cards-showstatus-5: none;
  --general-cards-status-new: rgba(144, 168, 40, 0.75);
  --general-cards-status-update: rgba(193, 136, 82, 0.75);
  --general-mode-states-menuopen: none;
  --general-mode-states-showchapter: none;
  --general-mode-states-showmovement: initial;
  --general-showpause: initial;
  --general-showplay: none;
  --general-tabs-tab-off-color: rgba(255, 255, 255, 1);
  --general-tabs-tab-off-text: rgba(102, 112, 133, 1);
  --general-tabs-tab-on-color: rgba(235, 241, 249, 1);
  --general-tabs-tab-on-text: rgba(41, 83, 137, 1);
  --gray-100: rgba(242, 244, 247, 1);
  --gray-200: rgba(234, 236, 240, 1);
  --gray-25: rgba(252, 252, 253, 1);
  --gray-300: rgba(208, 213, 221, 1);  /* #E2EDAB */
  --gray-300-50pct: rgba(208, 213, 221, .5);
  --gray-400: rgba(152, 162, 179, 1);
  --gray-400-50pct: rgba(152, 162, 179, .5);
  --gray-50: rgba(249, 250, 251, 1);
  --gray-500: rgba(102, 112, 133, 1);
  --gray-600: rgba(71, 84, 103, 1);  /* #475466 */
  --gray-700: rgba(52, 64, 84, 1);
  --gray-800: rgba(29, 41, 57, 1);
  --gray-900: rgba(16, 24, 40, 1);
  --gray-950: rgba(12, 17, 29, 1);
  --movements-option: 1px;
  --noteconnection: rgba(240, 171, 251, 1);
  --notecultural-expectation: rgba(255, 194, 194, 1);
  --notedevotional: rgba(178, 253, 212, 1);
  --noteintroduction: rgba(251, 243, 171, 1);
  --noteteam-qa: rgba(255, 181, 95, 1);
  --notetranslation-decision: rgba(184, 247, 255, 1);
  --primary-100: rgba(216, 228, 243, 1);
  --primary-200: rgba(176, 200, 232, 1);
  --primary-25: rgba(240, 244, 250, 1);
  --primary-300: rgba(137, 173, 220, 1);  /* #89ADDC */
  --primary-400: rgba(98, 146, 208, 1);
  --primary-50: rgba(235, 241, 249, 1);
  --primary-500: rgba(66, 124, 199, 1);
  --primary-600: rgba(53, 106, 177, 1);
  --primary-700: rgba(41, 83, 137, 1);  /* #295389 */
  --primary-800: rgba(35, 71, 118, 1);  /* #234776 */
  --primary-900: rgba(23, 47, 79, 1);
  --primary-950: rgba(12, 24, 39, 1);

  /* Frames */
  --frame-main-padding: 48px 8px;
  --frame-main-padding-left: 8px;
  --frame-main-padding-right: 8px;

  /* Buttons */
  --button-xs-radius: 12px;
  --button-sm-radius: 24px;
  --button-md-radius: 33px;
  --button-lg-radius: 48px;

  /* Shadow Effects */
  --shadow-box-bottom: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --shadow-button-drop-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25), -1px -1px 2px 0px rgba(19, 92, 185, 0.25);
  --shadow-button-hover: 1px 1px 2px 0px rgba(19, 92, 185, 0.25);
  --shadow-drop-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25), -2px -2px 10px 0px rgba(19, 92, 185, 0.35);
  --shadow-carousel-card-drop-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25), -1px -1px 5px 0px rgba(19, 92, 185, 0.35);
  --shadow-audio-card-drop-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  --shadow-list-card-drop-shadow: 0px 3px 4.5px -1.5px rgba(16, 24, 40, 0.03), 0px 9px 12px -3px rgba(16, 24, 40, 0.08);
  --shadow-movement-card-drop-shadow: 2.5px 2.5px 6.5px 0px rgba(0, 0, 0, 0.25), -2.5px -2.5px 6.5px 0px rgba(19, 92, 185, 0.35);
  --shadow-lg: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  --shadow-md: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  --shadow-sm-focused-4px-primary-100: 0px 0px 0px 4px rgba(244, 235, 255, 1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  --shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  --shadow-xs-focused-4px-primary-100: 0px 0px 0px 4px rgba(244, 235, 255, 1), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
 
 
  /* Display Fonts */
  --display-md-semibold-font-family: var(--primary-font-family);
  --display-md-semibold-font-size: 36px;
  --display-md-semibold-font-style: normal;
  --display-md-semibold-font-weight: 600;
  --display-md-semibold-letter-spacing: -0.72px;
  --display-md-semibold-line-height: 44px;
  --display-sm-semibold-font-family: var(--primary-font-family);
  --display-sm-semibold-font-size: 30px;
  --display-sm-semibold-font-style: normal;
  --display-sm-semibold-font-weight: 600;
  --display-sm-semibold-letter-spacing: 0px;
  --display-sm-semibold-line-height: 38px;
  --display-xs-medium-font-family: var(--primary-font-family);
  --display-xs-medium-font-size: 24px;
  --display-xs-medium-font-style: normal;
  --display-xs-medium-font-weight: 500;
  --display-xs-medium-letter-spacing: -0.48px;
  --display-xs-medium-line-height: 32px;
  --display-sm-medium-font-family: var(--primary-font-family);
  --display-sm-medium-font-size: 30px;
  --display-sm-medium-font-style: normal;
  --display-sm-medium-font-weight: 500;
  --display-sm-medium-letter-spacing: -0px;
  --display-sm-medium-line-height: 38px;
  --display-md-medium-font-family: var(--primary-font-family); 
  --display-md-medium-font-size: 36px;
  --display-md-medium-font-style: normal;
  --display-md-medium-font-weight: 500;
  --display-md-medium-letter-spacing: -0.6px;
  --display-md-medium-line-height: 44px;
  --display-lg-medium-font-family: var(--primary-font-family); 
  --display-lg-medium-font-size: 48px;
  --display-lg-medium-font-style: normal;
  --display-lg-medium-font-weight: 500;
  --display-lg-medium-letter-spacing: -0.9px;
  --display-lg-medium-line-height: 60px;
  --display-xl-medium-font-family: var(--primary-font-family); 
  --display-xl-medium-font-size: 60px;
  --display-xl-medium-font-style: normal;
  --display-xl-medium-font-weight: 500;
  --display-xl-medium-letter-spacing: -1.2px;
  --display-xl-medium-line-height: 72px;
  
  /* Regular text fonts */
  --text-lg-regular-font-family: var(--primary-font-family);
  --text-lg-regular-font-size: 18px;
  --text-lg-regular-font-style: normal;
  --text-lg-regular-font-weight: 400;
  --text-lg-regular-letter-spacing: 0px;
  --text-lg-regular-line-height: 28px;
  --text-lg-medium-font-family: var(--primary-font-family);
  --text-lg-medium-font-size: 18px;
  --text-lg-medium-font-style: normal;
  --text-lg-medium-font-weight: 500;
  --text-lg-medium-letter-spacing: 0px;
  --text-lg-medium-line-height: 28px;
  --text-lg-semibold-font-family: var(--primary-font-family);
  --text-lg-semibold-font-size: 18px;
  --text-lg-semibold-font-style: normal;
  --text-lg-semibold-font-weight: 600;
  --text-lg-semibold-letter-spacing: 0px;
  --text-lg-semibold-line-height: 28px;
  --text-md-medium-font-family: var(--primary-font-family);
  --text-md-medium-font-size: 16px;
  --text-md-medium-font-style: normal;
  --text-md-medium-font-weight: 500;
  --text-md-medium-letter-spacing: 0px;
  --text-md-medium-line-height: 24px;
  --text-md-regular-font-family: var(--primary-font-family);
  --text-md-regular-font-size: 16px;
  --text-md-regular-font-style: normal;
  --text-md-regular-font-weight: 400;
  --text-md-regular-letter-spacing: 0px;
  --text-md-regular-line-height: 24px;
  --text-md-semibold-font-family: var(--primary-font-family);
  --text-md-semibold-font-size: 16px;
  --text-md-semibold-font-style: normal;
  --text-md-semibold-font-weight: 600;
  --text-md-semibold-letter-spacing: 0px;
  --text-md-semibold-line-height: 24px;
  --text-sm-medium-font-family: var(--primary-font-family);
  --text-sm-medium-font-size: 14px;
  --text-sm-medium-font-style: normal;
  --text-sm-medium-font-weight: 500;
  --text-sm-medium-letter-spacing: 0px;
  --text-sm-medium-line-height: 20px;
  --text-sm-regular-font-family: var(--primary-font-family);
  --text-sm-regular-font-size: 14px;
  --text-sm-regular-font-style: normal;
  --text-sm-regular-font-weight: 400;
  --text-sm-regular-letter-spacing: 0px;
  --text-sm-regular-line-height: 20px;
  --text-sm-regular-underlined-font-family: var(--primary-font-family);
  --text-sm-regular-underlined-font-size: 14px;
  --text-sm-regular-underlined-font-style: normal;
  --text-sm-regular-underlined-font-weight: 400;
  --text-sm-regular-underlined-letter-spacing: 0px;
  --text-sm-regular-underlined-line-height: 20px;
  --text-sm-semibold-font-family: var(--primary-font-family);
  --text-sm-semibold-font-size: 14px;
  --text-sm-semibold-font-style: normal;
  --text-sm-semibold-font-weight: 600;
  --text-sm-semibold-letter-spacing: 0px;
  --text-sm-semibold-line-height: 20px;
  --text-xl-regular-font-family: var(--primary-font-family);
  --text-xl-regular-font-size: 20px;
  --text-xl-regular-font-style: normal;
  --text-xl-regular-font-weight: 400;
  --text-xl-regular-letter-spacing: 0px;
  --text-xl-regular-line-height: 30px;
  --text-xl-semibold-font-family: var(--primary-font-family);
  --text-xl-semibold-font-size: 20px;
  --text-xl-semibold-font-style: normal;
  --text-xl-semibold-font-weight: 600;
  --text-xl-semibold-letter-spacing: 0px;
  --text-xl-semibold-line-height: 30px;
  --text-xs-regular-font-family: var(--primary-font-family);
  --text-xs-regular-font-size: 12px;
  --text-xs-regular-font-style: normal;
  --text-xs-regular-font-weight: 500;
  --text-xs-regular-letter-spacing: 0px;
  --text-xs-regular-line-height: 18px;
  --text-xs-medium-font-family: var(--primary-font-family);
  --text-xs-medium-font-size: 12px;
  --text-xs-medium-font-style: normal;
  --text-xs-medium-font-weight: 500;
  --text-xs-medium-letter-spacing: 0px;
  --text-xs-medium-line-height: 18px;
  --text-xs-semibold-font-family: var(--primary-font-family);
  --text-xs-semibold-font-size: 12px;
  --text-xs-semibold-font-style: normal;
  --text-xs-semibold-font-weight: 600;
  --text-xs-semibold-letter-spacing: 0px;
  --text-xs-semibold-line-height: 18px;
  --text-xxs-bold-font-family: var(--primary-font-family);
  --text-xxs-bold-font-size: 10px;
  --text-xxs-bold-font-style: normal;
  --text-xxs-bold-font-weight: 700;
  --text-xxs-bold-letter-spacing: 0px;
  --text-xxs-bold-line-height: 150%;
  --text-xxs-medium-font-family: var(--primary-font-family);
  --text-xxs-medium-font-size: 10px;
  --text-xxs-medium-font-style: normal;
  --text-xxs-medium-font-weight: 500;
  --text-xxs-medium-letter-spacing: 0px;
  --text-xxs-medium-line-height: 150%;
  --text-xxs-regular-font-family: var(--primary-font-family);
  --text-xxs-regular-font-size: 10px;
  --text-xxs-regular-font-style: normal;
  --text-xxs-regular-font-weight: 400;
  --text-xxs-regular-letter-spacing: 0px;
  --text-xxs-regular-line-height: 150%;
  --text-xxs-semibold-font-family: var(--primary-font-family);
  --text-xxs-semibold-font-size: 10px;
  --text-xxs-semibold-font-style: normal;
  --text-xxs-semibold-font-weight: 600;
  --text-xxs-semibold-letter-spacing: 0px;
  --text-xxs-semibold-line-height: 150%;
  --tooltip-font-family: var(--primary-font-family);
  --tooltip-font-size: 12px;
  --tooltip-font-style: normal;
  --tooltip-font-weight: 500;
  --tooltip-letter-spacing: 0px;
  --tooltip-line-height: 150%;
}

/*

To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

<body data-breakpoints-mode="desktop">
    <!-- the rest of your content -->
</body>

You can apply the theme on any DOM node, not just the `body`

*/

[data-breakpoints-mode="desktop"] {
  --breakpoints-halftheight: 465px;
  --breakpoints-height: 935px;
  --breakpoints-minbookheight: 50rem;
  --breakpoints-minelementsheight: 25rem;
  --breakpoints-showhamburger: none;
  --breakpoints-showmenuitems: initial;
  --breakpoints-width: 1064px;
  --frame-main-padding: calc(min(4vh,48px)) 8px;
  --frame-main-padding-left: 8px;
  --frame-main-padding-right: 8px;
}

[data-breakpoints-mode="tablet"] {
  --breakpoints-halftheight: 422px;
  --breakpoints-height: 844px;
  --breakpoints-minbookheight: 40rem;
  --breakpoints-minelementsheight: 20rem;
  --breakpoints-showhamburger: none;
  --breakpoints-showmenuitems: initial;
  --breakpoints-width: 643px;
  --frame-main-padding: calc(min(3vh,24px))  8px;
  --frame-main-padding-left: 8px;
  --frame-main-padding-right: 8px;
}

[data-breakpoints-mode="mobile"] {
  --breakpoints-halftheight: 426px;
  --breakpoints-height: 852px;
  --breakpoints-minbookheight: 30rem;
  --breakpoints-minelementsheight: 20rem;
  --breakpoints-showhamburger: initial;
  --breakpoints-showmenuitems: none;
  --breakpoints-width: 390px;
  --frame-main-padding: calc(min(2vh,12px))  4px;
  --frame-main-padding-left: 4px;
  --frame-main-padding-right: 4px;
}