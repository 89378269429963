:root {
  --card-height-large: 350px;
  --card-width-large: 350px;
  --card-height-small: 120px;
  --card-width-small: 120px;
  --card-height-carousel: 180px;
  --card-width-carousel: 180px;
  --border-width-large: 4px;
  --border-width-small: 4px;
}

.element-card {  
  position: relative;
}

div.element-card {
  align-items: center;
  display: flex;
  flex-flow: column;  
}

div.element-card.card-state-large {
  max-width: var(--card-width-large);
}

div.element-card.card-state-small {
  max-width: var(--card-width-small);
}

/* div.element-card.card-state-carousel {
  position: relative;
  height: 90%;
  overflow: hidden;
} */

.element-card.card-state-large .div.instance-node {
  border-radius: 5%;
  border-style: solid;
  border-color: transparent;
  border-width: var(--border-width-large);
  height: calc(var(--card-height-large) + 2*var(--border-width-large));
  width: calc(var(--card-width-large) + 2*var(--border-width-large));
  position: relative;
}

.element-card.card-state-small .div.instance-node {
  border-radius: 5%;
  border-style: solid;
  border-color: transparent;
  border-width: var(--border-width-small);
  height: calc(var(--card-height-small) + 2*var(--border-width-small));
  width: calc(var(--card-width-small) + 2*var(--border-width-small));
  position: relative;
}

.element-card .card-image {  
  border-radius: 5%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  box-shadow: var(--shadow-drop-shadow);
}

.element-card .card-state-large .card-image {  
  height: var(--card-height-large);
  width: var(--card-width-large);
}

.element-card .card-state-small .card-image {    
  height: var(--card-height-small);
  width: var(--card-width-small);
}

.element-card .card-image.card-image-carousel  {    
  position: relative !important;
  height: 95%;
  /* width: 95%;   */
}

.element-card .subheading {
  align-items: center;
  display: flex;  
  width: 95%;
  margin-top: 0.5rem;
}

.element-card button.icon-img {
  padding: 0;
  background: transparent;
  border: 0;
}

.element-card button.icon-img-after {
  padding-left: 2px;
}

.element-card button .title-icon-img {
  color: black;
  align-self: stretch;
  flex: 0 0 auto;
  position: relative;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  width: 30px;
  z-index: 2;
}

.element-card .card-name {  
  flex: 1;
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  color: var(--gray-700);
}

.element-card.card-state-large .card-name {
  font-family: var(--text-lg-medium-font-family);
  font-size: var(--text-lg-medium-font-size);
  font-style: var(--text-lg-medium-font-style);
  font-weight: var(--text-lg-medium-font-weight);  
  letter-spacing: var(--text-lg-medium-letter-spacing);
  line-height: var(--text-lg-medium-line-height);  
}

.element-card.card-state-small .card-name,
.element-card.card-state-carousel .card-name {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);  
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);  
}

.element-card .card-image-large {
  height: var(--card-height-large);
  width: var(--card-width-large);
}

.element-card .card-image-small {
  height: var(--card-height-small);
  width: var(--card-width-small);
}

.obt-quick-play.card-quickplay-small .obt-play-circle,
.obt-download-list.card-download-small,
.obt-download-list svg.card-download-small  {
  height: 1rem;
  width: 1rem;
}

.obt-quick-play.card-quickplay-large .obt-play-circle,
.obt-download-list.card-download-large,
.obt-download-list svg.card-download-large  {  
  height: 1.5rem;
  width: 1.5rem;
}

.element-card .obt-quick-play.card-quickplay-large,
.element-card .obt-quick-play.card-quickplay-small
 {
  position: absolute !important;
  bottom: 0%;
  right: 0.1%;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.element-card input:hover {
  transform: scale(1.05)
}

.element-card .obt-quick-play.card-quickplay-large:hover {
  bottom: 1%;
  right: 2%;
}

.element-card .with-overlay .card-image {
  opacity: 0.5;
}

/* overlay for chapter number */
.element-card .image-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--gray-700);
  /* opacity: .75; */
  text-shadow: var(--shadow-drop-shadow);
  font-family: var(--text-xl-semibold-font-family);
  font-style: var(--text-xl-semibold-font-style);
  font-weight: var(--text-xl-semibold-font-weight);
}

.element-card.card-state-small .image-overlay {    
    font-size: calc(var(--card-width-small)/2.5);
}

.element-card.card-state-large .image-overlay {
  font-size: calc(var(--card-width-large)/2.5);
}


.element-card .scripture-address {
  position: absolute;
  bottom: 1%;
  right: 5%;
  color: var(--gray-50);
  opacity: .75;
  text-shadow: var(--shadow-drop-shadow);
  font-family: var(--text-xs-semibold-font-family);
  font-style: var(--text-xs-semibold-font-style);
  font-weight: var(--text-xs-semibold-font-weight);
  font-size: var(--text-xs-semibold-font-size);
}

.element-card .with-overlay .scripture-address {
  color: var(--gray-800);
}


/* For placeholder cards during loading */
.skeleton-box  {
  width: unset !important;
  overflow: hidden;
}

.skeleton-box .element-card .card-image {
  opacity: 0.1;
}

.skeleton-box .element-card .icon-img {
  display:none;    
}

.skeleton-box .element-card .card-name {     
  color: var(--gray-200);
  margin: 0.5rem 1rem 0rem 0.5rem;      
}
