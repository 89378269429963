
.Bible-info {
  align-items: flex-start;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadow-drop-shadow);
  display: flex;
  flex-direction: column;  
  position: absolute;
  overflow: visible;
  /* width: 336px; */
  z-index: 4;
  top: 140%;  
  left: 2rem;
}

.Bible-info .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  position: relative;
  width: 100%;
}

.Bible-info .nav-menu-item {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 16px 16px;
  padding: 12px;
  position: relative;
  width: 100%;
}

.Bible-info .akuo-icon {
  height: 30px;
  object-fit: cover;
  position: relative;
  width: 29.64px;
}

.Bible-info .title {
  color: #475466;
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.Bible-info .description {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  margin-right: -2px;
  position: relative;
}

.Bible-info .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 290px;
  text-wrap: wrap;
}

.Bible-info .span-wrapper {
  align-self: stretch;
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  position: relative;
}

.Bible-info .text-wrapper {
  color: #475466;
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
}

.Bible-info .copyright {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  margin-right: -12px;
  padding: 10px 0px;
  position: relative;
  width: 300px;
}

.Bible-info .copyright-text {
  margin-top: -1px;
  position: relative;  
  text-wrap: wrap;
}

.Bible-info .copyright-text a span {
  color: var(--apm-project-blue500);
}

.Bible-info .span,
.Bible-info .copyright-text  {
  color: var(--gray-700);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
}

.Bible-info .copyright-image {
  height: 23.25px;
  object-fit: cover;
  position: relative;
  width: 66px;
}
/* Show and Hide the Bible Info overlay */
.Bible-info {  
  visibility: hidden;
  opacity: 0;
  transition: all var(--speed) ease-out;
}

.info-bible-icon:hover+.Bible-info{
  visibility: visible;
  overflow: visible;
  opacity: 1;
  transition: all var(--speed) ease-in;
}

.Bible-info:hover  {
  visibility: visible;
  overflow: visible;
  opacity: 1;
  transition: all var(--speed) ease-in; 
}

.info-bible-icon:hover {
  cursor:help;
}

.info-bible-container {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
