:root {
  --speed: 300ms; 
}

/* Use to outline all elements in red for troubleshooting */
/* * {  
  outline: 1px solid red;
} */

.obt-menu-item {
  align-items: center;
  border-radius: 8.87px;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  left: 20px;
  /* overflow: hidden; */
  padding: 8px 15px;
  position: relative;
}

.obt-menu-item a {
  color: var(--gray-600);
}

.obt-menu-item .obt-menu-text {
  /* color: #475466; */
  color: var(--gray-600);
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
  margin-top: -1.11px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.navigation.tablet .obt-menu-text {
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
}

.obt-menu-item img.line {
  height: 1px;  
  position: absolute;
  width: 100%;
}

.obt-menu-item .dropdown-menu {  
  visibility: hidden;
  opacity: 0;
  /* left: 1rem; */
  transition: all var(--speed) ease-out;
}

.obt-menu-item.state-1-hover {
  overflow: visible;
  z-index: 9;
}

.obt-menu-item.state-1-hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  border-color: var(--gray-200);
  border-width: 1px;
  box-shadow: var(--shadow-drop-shadow);
  transition: all var(--speed) ease-in;
}

