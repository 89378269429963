.language-label {
  display:grid;
  justify-content: center;
  border-style: solid ;
  border-width: 1px;
  border-radius: 6px;
  color: var(--gray-400);
  min-width: 2rem;
}
p.language-text {
  margin-block-start: 0em !important;
  margin-block-end:  0em !important;
  color: var(--gray-400);  
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  padding: 2px;
}