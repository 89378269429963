:root {
  --resource-card-height-large: 120px;
  --resource-card-width-large: 120px;
  --resource-card-height-small: 80px;
  --resource-card-width-small: 80px;
}

.obtresource-card {  
  position: relative;  
  width: 90%;
}

.obtresource-card-header {
  position: relative;
  align-items: center;
  display: flex;
  flex-flow: row;    
  box-shadow: 0px 1px 1px #00000040;
  transition: background-color 0.5s ease; /* Transition for the background color */
  z-index: 1;
}

.obtresource-card-header:hover {
  background-color: var(--primary-50);
  cursor: pointer;
}


div.obtresource-card.card-state-large {
  gap: 0.5rem;
}

div.obtresource-card.card-state-small {
  gap: 0.5rem;
}

.obtresource-card.card-state-large .image-wrapper {
  min-height: var(--resource-card-height-large);
  width: 100%;
  position: relative;
  display: flex;
  flex: 1 1;
}

.obtresource-card.card-state-small .image-wrapper {
  min-height: var(--resource-card-height-small);
  width: 100%;
  position: relative;
  display: flex;
  flex: 1 1;
}

.obtresource-card .image-wrapper.shimmer {
  min-height: unset;
}

.obtresource-card .resource-image {  
  border-radius: 5%;
  position: relative;
  box-shadow: var(--shadow-drop-shadow);
}

.obtresource-card .card-state-large .resource-image {  
  height: var(--resource-card-height-large);
  width: var(--resource-card-width-large);
}

.obtresource-card .card-state-small .resource-image {    
  height: var(--resource-card-height-small);
  width: var(--resource-card-width-small);
}

.obtresource-card .subheading {
  align-items: center;
  display: flex;  
  width: 95%;
  margin-top: 0.5rem;
}

.obtresource-card button.icon-img {
  padding: 0;
  background: transparent;
  border: 0;
}

.obtresource-card button.icon-img-after {
  padding-left: 2px;
}

.obtresource-card button .title-icon-img {
  color: black;
  align-self: stretch;
  flex: 0 0 auto;
  position: relative;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  width: 30px;
  z-index: 4;
}

.obtresource-card .card-name {  
  flex: 1;
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  color: var(--gray-700);
}

.obtresource-card .shimmer .card-name {  
  color: var(--gray-100);
}

.obtresource-card.card-state-large .card-name {
  font-family: var(--text-lg-medium-font-family);
  font-size: var(--text-lg-medium-font-size);
  font-style: var(--text-lg-medium-font-style);
  font-weight: var(--text-lg-medium-font-weight);  
  letter-spacing: var(--text-lg-medium-letter-spacing);
  line-height: var(--text-lg-medium-line-height);  
}

.obtresource-card.card-state-small .card-name {
  font-family: var(--text-lg-medium-font-family);
  font-size: var(--text-lg-medium-font-size);
  font-style: var(--text-lg-medium-font-style);
  font-weight: var(--text-lg-medium-font-weight);  
  letter-spacing: var(--text-lg-medium-letter-spacing);
  line-height: var(--text-lg-medium-line-height);  
}

.obtresource-card .frame-wrapper {
  align-items: flex-end;
  display: flex;
  gap: 9px;
  height: 38px;
  justify-content: center;
  left: 11px;
  position: absolute;
  top: 202px;
  width: 208px;
}

.obtresource-card .frame-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 3.75px;
  justify-content: center;
  margin-left: -2.25px;
  margin-right: -2.25px;
  padding: 0px 3.75px;
  position: relative;
  width: 212.25px;
}

.obtresource-card .resource-image-large {
  height: var(--resource-card-height-large);
  width: var(--resource-card-width-large);
}

.obtresource-card .resource-image-small {
  height: var(--resource-card-height-small);
  width: var(--resource-card-width-small);
}

.obt-quick-play.card-quickplay-small .obt-play-circle,
.obt-download-list.card-download-small,
.obt-download-list svg.card-download-small  {
  height: 1rem;
  width: 1rem;
}

.obt-quick-play.card-quickplay-large .obt-play-circle,
.obt-download-list.card-download-large,
.obt-download-list svg.card-download-large  {  
  height: 1.5rem;
  width: 1.5rem;
}

.obtresource-card .obt-quick-play.card-quickplay-large,
.obtresource-card .obt-quick-play.card-quickplay-small
 {
  position: absolute !important;
  bottom: 0%;
  right: 0.1%;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.obtresource-card .obt-quick-play.card-quickplay-large:hover {
  bottom: 1%;
  right: 2%;
}

/* For placeholder cards during loading */
/* .skeleton-box  .obtresource-card {
  width: unset !important;
  overflow: hidden;
} */

.skeleton-box .obtresource-card .card-image {
  opacity: 0.1;
}

.skeleton-box .obtresource-card .icon-img {
  display:none;    
}

.skeleton-box .obtresource-card .card-name {     
  color: var(--gray-200);
  margin: 0.5rem 1rem 0rem 0.5rem;      
}

.obtresource-card .expand-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: right;
  margin-right: 1.5rem;
  gap: 0.5rem;
  color: var(--gray-700);
  z-index: 3;
}

.obtresource-card .expand-resource {
  width: 32px;
  height: 32px;
}

.obtresource-card .resource-download {
  width: 28px;
  height: 28px;
  z-index: 4;
  position: relative;
  overflow:visible;
  background-color: transparent;
}
  

.obtresource-card .resource-quickplay {
  width: 32px;
  height: 32px;
  z-index: 4;
}

.obtresource-card .resource-download:hover {
  background-color: transparent;
  box-shadow:none;
  cursor: pointer;
  transform: scale(1.2);
}

.obtresource-card .title-icon-img:hover {
  transform: scale(1.2); 
}

.skeleton-box .obtresource-card .expand-wrapper {
  display: none;
}