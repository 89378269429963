.navigation {
  position: relative;
  display: var(--breakpoints-showmenuitems, inline-flex);
  /* flex-grow: 1; */
  flex: 1 1 auto;
  align-items: flex-start;
  justify-content: center;
  /* gap: 0.5rem; */
  padding: 0px 10px;
}

.navigation.tablet {
  /* gap: 4px; */
  font-size: 0.8rem;
}

.navigation .obt-menu-item-instance {
  flex: 0 0 auto !important;
  left: unset !important;
  top: unset !important;
}

.navigation.mobile {
  position: relative;
  display: inline-flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  padding: 0px 4px;
  max-width: 12rem;
  box-shadow: var(--shadow-drop-shadow);
  transition: all var(--speed) ease-in;
}
