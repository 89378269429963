.svg-container {  
  position: relative;
  color: black;
  align-self: stretch;
  flex: 0 0 auto;
  background-size: contain;
  cursor: pointer;
  /* display: inline-block; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;
  width: 28px;
  height: 28px;
}

.svg-container.shimmer {
  opacity: 0;
}

.wave-group {
  width: 30px;
  height: 30px;
}

.svg-container > svg  {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.svg-container .active svg
{
  opacity: 1;
}

.svg-container :not(.active) svg
{
  opacity: 0;
}