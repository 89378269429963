.obt-button-bible-home {
  width: fit-content;
  gap: 0;
  /* overflow: hidden; */
}

/* .mobile .obt-button-bible-home {
  gap: 0;
} */

.tablet .obt-button-bible-home .text-button {
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);

}

.mobile .obt-button-bible-home .text-button {
    font-family: var(--text-xs-semibold-font-family);
    font-size: var(--text-xs-semibold-font-size);
    font-style: var(--text-xs-semibold-font-style);
    font-weight: var(--text-xs-semibold-font-weight);
    letter-spacing: var(--text-xs-semibold-letter-spacing);
    line-height: var(--text-xs-semibold-line-height);
}
