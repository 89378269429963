.progress-bar-outer {
    border: 1px solid;
    height: 1rem;
    border-radius: 0.5rem;
}

.progress-bar-inner {
    height: 100%;
    background-color: var(--primary-500);
    width: var(--width);
    transition: width 0.5s ease;
    text-align: center;    
    color: white;
    font-size: 0.8rem;
    /* font-weight: 400; */
    vertical-align: center;
}