.OBT-footer { 
  position: relative; 
  display: flex;
  flex: 0 0 auto;
  align-self: stretch;
  justify-content: center;
  padding: 0 0.5rem;
}

.OBT-footer.mobile,
.OBT-footer.tablet {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }

.OBT-footer .footer-text {
  color: var(--gray-500);
  flex: 1;
  font-family: var(--text-md-regular-font-family);
  font-size: var(--text-md-regular-font-size);
  font-style: var(--text-md-regular-font-style);
  font-weight: var(--text-md-regular-font-weight);
  letter-spacing: var(--text-md-regular-letter-spacing);
  line-height: var(--text-md-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.OBT-footer .footer-links {
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;  
}

.OBT-footer .footer-link {
  color: var(--gray-500);
  font-family: var(--text-md-regular-font-family);
  font-size: var(--text-md-regular-font-size);
  font-style: var(--text-md-regular-font-style);
  font-weight: var(--text-md-regular-font-weight);
  letter-spacing: var(--text-md-regular-letter-spacing);
  line-height: var(--text-md-regular-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
