:root {
  --toggle-height-desktop: 32px;
  --toggle-width-desktop: 139px;
  --toggle-height-tablet: 28px;
  --toggle-width-tablet: 124px;
  --toggle-height-mobile: 26px; 
  --toggle-width-mobile: 112px;
}

/* Hide the original checkbox */
.story-mode input {
  opacity: 0;
  width: 0;
  height: 0;
}

.story-mode {
  border-radius: var(--button-md-radius);
  box-shadow: inset -3.75px -3.75px 7.5px #ffffff, inset 2.5px 2.5px 7.5px #00000059;
  position: relative;
  height: var(--toggle-height-desktop); 
  width: var(--toggle-width-desktop);   
}

.story-mode.tablet {
  transform: scale(90%,90%);
  -webkit-transform: scale(90%,90%);
}

.story-mode.mobile {
  transform: scale(80%,80%);
  -webkit-transform: scale(80%,80%);
}

.story-mode:hover {
  cursor: pointer;
}

.story-mode .text {
  color: var(--gray-600);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  /*height: 20px;*/
  left: 4px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.story-mode .text-frame {
  position: absolute;
}


.story-mode.traditional {
  background-color: var(--gray-200);
  transition: 0.4s;
}

.story-mode.story {
  background-color: var(--apm-project-blue100);
  transition: 0.4s;
}


.story-mode .ellipse {
  position: absolute;
}

.story-mode .ellipse {
  height: 20px;
  left: 37px;
  top: 5px;
  width: 88px;
}

.story-mode.story .ellipse {
  background-color: var(--apm-project-blue100);
  border-radius: 14px;
  box-shadow: -1px -1px 6.25px #ffffff, 0.75px 0.75px 6.25px #00000066, inset -1.5px -1.5px 5px #0000004c,
    inset 1.5px 1.5px 5px #ffffffcc;
  height: 28px;
  left: 105px;
  top: 2px;
  width: 28px;
  transition: 0.4s;
}

.story-mode.traditional .ellipse {
  background-color: var(--gray-200);
  border-radius: 14px;
  box-shadow: -1px -1px 6.25px #ffffff, 0.75px 0.75px 6.25px #00000066, inset -1.5px -1.5px 5px #0000004c,
    inset 1.5px 1.5px 5px #ffffffcc;
  height: 28px;
  left: 5px;
  top: 2px;
  width: 28px;
  transition: 0.4s;
}

.story-mode.story .text-frame {
  height: 20px;
  left: 13px;
  top: 25%;
  width: 88px;
  transition: 0.4s;
}

.story-mode.traditional .text-frame {
  height: 20px;
  left: 25%;
  top: 25%;
  width: 88px;
  transition: 0.4s;
}
