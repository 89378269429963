.popup-message-container {
  position: absolute;
  top: -3%;
  left: 10%;
  width: 12rem;
  background: var(--gray-200);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 3;
  overflow: visible;
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  text-align: left;
  color: var(--primary-800);
  box-shadow: var(--shadow-button-drop-shadow);
}

.popup-message-container .popup-message-icon {  
  transform:scale(150%);
  color: var(--primary-800);
}

.popup-message-container .popup-message-icon.left {  
  margin-right: 1rem;
}

.popup-message-container .popup-message-icon.right {  
  margin-left: 1rem;
}
